import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";
import "../index.css";
import _ from 'lodash';
import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';

export default class Container extends Component {
  constructor(props){
    super(props)
    this.state = { initialData: props.data, actualData: props.data }
  }

  handleTreeChange (currentNode, selectedNodes) {
    let newData;
    newData = this.state.actualData.map((path1) => {
      if (currentNode._depth === 0) {
        if (currentNode.value === path1.value) {
          return {
            ...path1,
            active: currentNode.checked,
            checked: currentNode.checked,
            children: path1.children.map((path2) => ({
              ...path2,
              active: currentNode.checked,
              checked: currentNode.checked,
              children: path2.children.map((path3) => ({
                ...path3,
                active: currentNode.checked,
                checked: currentNode.checked,
              })),
            })),
          };
        } else {
          return path1;
        }
      } else if (currentNode._depth === 1) {
        const factor = _.get(this.state.actualData, currentNode.path).value;
        console.log("factor: ", factor);
        return {
          ...path1,
          children: path1.children.map((path2) => {
            if (path2.value === factor) {
              return {
                ...path2,
                checked: currentNode.checked,
                active: currentNode.checked,
                children: path2.children.map((path3) => ({
                  ...path3,
                  checked: currentNode.checked,
                  active: currentNode.checked,
                })),
              };
            } else {
              return path2;
            }
          }),
        };
      } else if (currentNode._depth === 2) {
        const factor = _.get(this.state.actualData, currentNode.path).value;
        console.log("factor: ", factor);
        return {
          ...path1,
          children: path1.children.map((path2) => ({
            ...path2,
            children: path2.children.map((path3) => {
              if (path3.value === factor) {
                return {
                  ...path3,
                  checked: currentNode.checked,
                  active: currentNode.checked,
                };
              } else {
                return path3;
              }
            }),
          })),
        };
      }
    });

    console.log("newData:", newData);

    this.state.actualData = newData;
    this.props.onChange(newData);
  };

  componentWillReceiveProps = (nextProps) => {
    if(!isEqual(nextProps.data, this.state.actualData)) {
      this.setState({ data: nextProps.data })
    }
  }

  shouldComponentUpdate = (nextProps) => {
    return !isEqual(nextProps.data, this.state.actualData)
  }

  // uncheckAll = () => {
  //   const data = this.state.data
  //   data[0].checked = false
  //   this.setState({data})
  // }

  // checkAll = () => {
  //   const data = this.state.data
  //   data[0].checked = true
  //   this.setState({ data })
  // }

  render() {
    const { data, onChange, ...rest } = this.props
    return (
      <div>
      <DropdownTreeSelect
      // className="bootstrap-demo"
      // className="mdl-demo" 
      data={this.state.initialData} 
      {...rest} 
      onChange={(currentNode) => this.handleTreeChange(currentNode)} />
      {/* <button onClick={this.checkAll}>Check all</button>
      <button onClick={this.uncheckAll}>Uncheck all</button> */}
      </div>
    )
  }
}