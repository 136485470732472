import * as React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SaveModal = ({
  open,
  handleClose,
  handleSave,
  setIndicatorName,
  setPredictionName, 
  successMessage,
  errorMessage,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {successMessage
            ? successMessage
            : errorMessage
            ? errorMessage
            : "Save Indicator"}
        </Typography>
        {successMessage && <Link to="/">Go to Dashboard</Link>}
        {!(successMessage || errorMessage) && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              onChange={(event) => {
                console.log("event:", event.target.value);
                if(setIndicatorName){
                  setIndicatorName(event.target.value);
                }else{
                  setPredictionName(event.target.value);
                }
              }}
            />
            <Button onClick={handleSave}>Save</Button>
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default SaveModal;
