import React from "react";
import { useHistory } from "react-router-dom";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";

// function preventDefault(event) {
//   event.preventDefault();
// }

const DatasourcesTable = ({
  datasourcesList,
  handleDelete,
  handleDownload,
}) => {
  let history = useHistory();

const redirectToCreateIndicatorPage = (id) => {
  history.push({
    pathname: "/create-indicator",
    state: id,
  });
};

  return (
    <React.Fragment>
      <Title>Recent Datasources</Title>
      <Table size="small">
        <TableBody>
          {datasourcesList &&
            datasourcesList.map((datasource) => (
              <TableRow key={datasource.id}>
                <TableCell>{datasource.name}</TableCell>
                <TableCell align="right">
                  <Button
                    onClick={()=>redirectToCreateIndicatorPage(datasource.id)}
                    variant="outlined"
                    startIcon={<AddIcon />}
                  >
                    Create Indicator
                  </Button>
                </TableCell>
                <TableCell align="right">
                  <Button
                    onClick={() => handleDownload(datasource.id)}
                    size="large"
                    startIcon={<DownloadIcon />}
                  ></Button>
                </TableCell>
                <TableCell align="right">
                  <Button
                    onClick={() => handleDelete(datasource.id)}
                    size="large"
                    startIcon={<DeleteForeverIcon />}
                  ></Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {/* <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more datasources
      </Link> */}
    </React.Fragment>
  );
};

export default DatasourcesTable;
