import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import axios from "axios";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { SocketContext } from "../contexts/socket";

import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";

// Import components
import CreatePrediction from "./CreatePrediction";
import PredictonModel from "./PredictonModel";
import Deploy from "./Deploy";
import Timeline from "../components/Timeline";
import MenuBar from "../components/MenuBar";
import Header from "../components/Header";

const mdTheme = createTheme();

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const Forecast = ({}) => {
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [timelineLevel, setTimelineLevel] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // ****** Métier *****
  const socket = useContext(SocketContext);

  const [isLoading, setIsLoading] = useState(false);
  const [tickersList, setTickersList] = useState();
  const [historicalData, setHistoricalData] = useState();
  const [datapartId, setDataPartId] = useState(undefined);
  const [experimentId, setExperimentId] = useState(undefined);
  const [predictionId, setPredictionId] = useState(undefined);
  const [predictionName, setPredictionName] = useState(undefined);
  const [tickerId, setTickerId] = useState(undefined);
  const [startDateInput, setStartDateInput] = useState(undefined);
  const [endDateInput, setEndDateInput] = useState(undefined);
  const [horizonInput, setHorizonInput] = useState(0);
  const [explicativeFactorsInput, setExplicativeFactorsInput] =
    useState(undefined);
  const [minDate, setMinDate] = useState(undefined);
  const [maxDate, setMaxDate] = useState(undefined);
  const [initialExplicativeFactors, setInitialExplicativeFactors] =
    useState(undefined);

  const [highestPredictedReturn, setHighestPredictedReturn] =
    useState(undefined);
  const [lowestPredictedReturn, setLowestPredictedReturn] = useState(undefined);
  const [expectedVar, setExpectedVar] = useState(undefined);
  const [expectedVol, setExpectedVol] = useState(undefined);

  const [experimentParameters, setExperimentParameters] = useState();
  const [experimentResults, setExperimentResults] = useState(undefined);
  const [factorsZeroImpact, setFactorsZeroImpact] = useState(undefined);

  const [query, setQuery] = useState(undefined);
  const FileDownload = require("js-file-download");

  const [timestampArray, setTimestampArray] = useState(undefined);
  const [timestamp, setTimestamp] = useState(undefined);
  const [lastTimestamp, setLastTimestamp] = useState(undefined);

  const [learningWindow, setLearningWindow] = useState(undefined);
  const [defaultValue, setDefaultValue] = useState(60);

  const [causalModels, setCausalModels] = useState(undefined);
  const [factorsInfluence, setFactorsInfluence] = useState(undefined);
  // ****** Métier *****

  // creating and connecting socket io
  useEffect(() => {
    socket.on("connect", function () {
      console.log("socket connected");
    });
    // server-side
    socket.on("experiment_results", (response) => {
      // read states is forbidden here
      console.log("experiment_results", response);
      setExperimentResults(response);
    });
  }, []);

  const assignObjectPaths = (obj, stack) => {
    Object.keys(obj).forEach((k) => {
      const node = obj[k];
      if (typeof node === "object") {
        node.path = stack ? `${stack}.${k}` : k;
        assignObjectPaths(node, node.path);
      }
    });
  };

  useEffect(() => {
    if (!experimentResults) {
      return;
    }
    if (
      (!datapartId || datapartId !== experimentResults.datapart_id) &&
      experimentResults.datapart_id
    ) {
      setDataPartId(experimentResults.datapart_id);
    }
    if (
      (!experimentId || experimentId !== experimentResults.id) &&
      experimentResults.id
    ) {
      setExperimentId(experimentResults.id);
    }
    if (
      (!predictionId || predictionId !== experimentResults.prediction_id) &&
      experimentResults.prediction_id
    ) {
      setPredictionId(experimentResults.prediction_id);
    }
    if (
      !startDateInput ||
      (startDateInput !== experimentResults?.date_interval?.min &&
        experimentResults?.date_interval?.min)
    ) {
      setStartDateInput(parseInt(experimentResults?.date_interval?.min));
    }
    if (
      !endDateInput ||
      (endDateInput !== experimentResults?.date_interval?.max &&
        experimentResults?.date_interval?.max)
    ) {
      setEndDateInput(parseInt(experimentResults?.date_interval?.max));
    }
    if (
      !minDate ||
      (minDate !== experimentResults?.date_interval_data?.min &&
        experimentResults?.date_interval_data?.min)
    ) {
      setMinDate(parseInt(experimentResults?.date_interval_data?.min));
    }
    if (
      !maxDate ||
      (maxDate !== experimentResults?.date_interval_data?.max &&
        experimentResults?.date_interval_data?.max)
    ) {
      setMaxDate(parseInt(experimentResults?.date_interval_data?.max));
    }
    if (
      !explicativeFactorsInput &&
      experimentResults.explicative_factors &&
      !_.isEqual(explicativeFactorsInput, experimentResults.explicative_factors)
    ) {
      assignObjectPaths(experimentResults.explicative_factors);
      setExplicativeFactorsInput(experimentResults.explicative_factors);
      console.log(
        "explicative factors input:",
        experimentResults.explicative_factors
      );
    }

    if (!initialExplicativeFactors && experimentResults.explicative_factors) {
      setInitialExplicativeFactors(experimentResults.explicative_factors);
      console.log(
        "initial explicative factors:",
        experimentResults.explicative_factors
      );
    }
  }, [experimentResults]);

  useEffect(async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_PREDICTOR}/get_all_tickers`,
      {
        headers: {
          token: JSON.parse(localStorage.getItem("user")).token,
        },
      }
    );
    setTickersList(response.data);
  }, []);

  useEffect(() => {
    if (tickerId) {
      console.log("tickerId changed");
      console.log("defaultValue:", defaultValue);
      setExperimentParameters({
        ...(experimentParameters ?? {}),
        target: tickerId,
        id: experimentId ? experimentId : undefined,
        date_interval: {
          start_date: startDateInput ? startDateInput : undefined,
          end_date: endDateInput ? endDateInput : undefined,
          learning_window: learningWindow ? learningWindow : defaultValue,
        },
        explicative_factors: explicativeFactorsInput
          ? explicativeFactorsInput
          : undefined,
        horizon: horizonInput ? horizonInput : 0,
      });
      // setConfidenceScore(undefined);
      setExpectedVar(undefined);
      setExpectedVol(undefined);
      setHighestPredictedReturn(undefined);
      setLowestPredictedReturn(undefined);
    }
  }, [tickerId]);

  useEffect(() => {
    if (
      tickerId &&
      explicativeFactorsInput &&
      experimentResults.explicative_factors &&
      !_.isEqual(explicativeFactorsInput, experimentResults.explicative_factors)
    ) {
      assignObjectPaths(experimentResults.explicative_factors);
      setExplicativeFactorsInput(experimentResults.explicative_factors);
      console.log(
        "explicative factors input:",
        experimentResults.explicative_factors
      );
    }
  }, [tickerId]);

  useEffect(() => {
    // client-side
    if (experimentParameters) {
      socket.emit("experiment_parameters", {
        parameters: experimentParameters,
      });
      console.log("experiment_parameters", {
        parameters: experimentParameters,
      });
    }
  }, [experimentParameters]);

  useEffect(async () => {
    console.log("get target first time");
    if (tickerId) {
      console.log("tickerId:", tickerId);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PREDICTOR}/targets/${tickerId}`,
          {
            date_interval: {
              start_date: minDate,
              end_date: maxDate,
            },
          },
          {
            headers: {
              token: JSON.parse(localStorage.getItem("user")).token,
            },
          }
        );
        console.log("GET Target response:", response.data);
        setHistoricalData(response.data);
      } catch (error) {
        console.log(error);
      }
      window.postMessage({
        type: "reset",
      });
    }
  }, [tickerId]);

  useEffect(async () => {
    console.log("get target again");
    if (tickerId && startDateInput && endDateInput) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PREDICTOR}/targets/${tickerId}`,
          {
            date_interval: {
              start_date: startDateInput,
              end_date: endDateInput,
            },
          },
          {
            headers: {
              token: JSON.parse(localStorage.getItem("user")).token,
            },
          }
        );
        console.log("GET Target response:", response.data);
        setHistoricalData(response.data);
      } catch (error) {
        console.log(error);
      }
      window.postMessage({
        type: "reset",
      });
    }
  }, [datapartId, startDateInput, endDateInput]);

  useEffect(() => {
    if (historicalData?.hist_data) {
      console.log("historical data updated");
      window.postMessage({
        type: "historical_data",
        data: historicalData.hist_data,
      });
    }
  }, [historicalData?.hist_data]);

  useEffect(() => {
    if (historicalData?.hist_data_vol) {
      console.log("historical volatility updated");
      window.postMessage({
        type: "historical_volatility",
        data: historicalData.hist_data_vol,
      });
    }
  }, [historicalData?.hist_data_vol]);

  useEffect(() => {
    if (experimentResults?.prediction?.prediction) {
      window.postMessage({
        type: "prediction_data",
        data: Object.values(experimentResults.prediction.prediction)[0]
          .prediction,
      });
      window.postMessage({
        type: "confidence_interval_data",
        data: Object.values(experimentResults.prediction.prediction)[0]
          .confidence_interval,
      });
    }
  }, [experimentResults?.prediction?.prediction]);

  // performance attribution
  useEffect(() => {
    if (experimentResults?.prediction?.prediction) {
      // window.postMessage({
      //   type: "influence_data",
      //   data: Object.values(experimentResults.prediction.prediction)[0]
      //     .factor_influences,
      // });
      console.log(
        "Influence:",
        Object.values(experimentResults.prediction.prediction)[0]
          .factor_influences
      );
      setFactorsInfluence(
        Object.values(experimentResults.prediction.prediction)[0]
          .factor_influences
      );
      setTickerId(
        Object.values(experimentResults.prediction.prediction)[0].target_name
      );
    }
  }, [experimentResults?.prediction?.prediction]);

  useEffect(() => {
    if (experimentResults?.prediction?.prediction) {
      setHighestPredictedReturn(
        Object.values(experimentResults.prediction.prediction)[0]
          .highest_predicted_return
      );
      setLowestPredictedReturn(
        Object.values(experimentResults.prediction.prediction)[0]
          .lowest_predicted_return
      );
      setExpectedVar(
        Object.values(experimentResults.prediction.prediction)[0].risk
          ?.expected_var
      );
      setExpectedVol(
        Object.values(experimentResults.prediction.prediction)[0].risk
          ?.expected_volatility
      );
      console.log(
        "CAUSAL MODELS:",
        Object.values(experimentResults.prediction.prediction)[0].models
      );
      setCausalModels(
        Object.values(experimentResults.prediction.prediction)[0].models
      );
      window.postMessage({
        type: "predicted_volatility",
        data: Object.values(
          experimentResults.prediction.prediction
        )[0].risk?.vol.map((v) => {
          return [v.timestamp, v.vol];
        }),
      });
      setIsLoading(false);
    }
  }, [experimentResults?.prediction?.prediction]);

  useEffect(() => {
    if (tickerId && horizonInput) {
      console.log("horizonInput changed");
      setExperimentParameters({
        ...(experimentParameters ?? {}),
        horizon: horizonInput,
        id: experimentResults?.id,
      });
      setIsLoading(true);
    }
  }, [horizonInput]);

  useEffect(() => {
    if (factorsInfluence) {
      console.log("influence data updated");
      window.postMessage({
        type: "influence_data",
        data: factorsInfluence,
      });
    }
  }, [factorsInfluence]);

  useEffect(() => {
    if (factorsInfluence) {
      window.postMessage({
        type: "target_info",
        data: tickerId,
      });
    }
  }, [factorsInfluence]);

  useEffect(() => {
    if (experimentResults?.prediction_id && datapartId) {
      window.postMessage({
        type: "reset-prediction",
      });
      window.postMessage({
        type: "reset-factors",
        data: Object.values(experimentResults.prediction.prediction)[0]
          .performance_attribution,
      });
    }
  }, [experimentResults?.prediction_id, datapartId]);

  useEffect(() => {
    if (initialExplicativeFactors) {
      setExperimentParameters({
        ...(experimentParameters ?? {}),
        id: experimentResults?.id,
        explicative_factors: initialExplicativeFactors,
      });
    }
  }, [initialExplicativeFactors]);

  const handleDownloadCsv = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PREDICTOR}/dataparts/${datapartId}?csv_download=yes`,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("user")).token,
          },
          responseType: "blob",
        }
      );
      FileDownload(response.data, "datapart.csv");
      console.log("response de download:", response.data);
    } catch (error) {
      console.log("error:", error);
    }
  };

  const handleDownloadPerf = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PREDICTOR}/model-predictions/${predictionId}?download_perf_attribution=true`,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("user")).token,
          },
          responseType: "blob",
        }
      );
      FileDownload(response.data, "prediction.csv");
      console.log("response de download perf:", response.data);
    } catch (error) {
      console.log("error:", error);
    }
  };

  const decrementHorizon = () => {
    const index = timestampArray.indexOf(timestamp);
    setTimestamp(timestampArray[index - 1]);
  };
  const incrementHorizon = () => {
    const index = timestampArray.indexOf(timestamp);
    setTimestamp(timestampArray[index + 1]);
  };

  useEffect(() => {
    if (timestamp !== undefined) {
      window.postMessage({
        type: "timestamp-changed",
        data: timestamp,
      });
    }
  }, [timestamp]);

  // ****** Métier ******

  const handleNext = () => {
    setTimelineLevel(timelineLevel + 1);
    window.postMessage({
      type: "historical_data",
      data: historicalData.hist_data,
    });
    window.postMessage({
      type: "historical_volatility",
      data: historicalData.hist_data_vol,
    });
    window.postMessage({
      type: "prediction_data",
      data: Object.values(experimentResults.prediction.prediction)[0]
        .prediction,
    });
    window.postMessage({
      type: "predicted_volatility",
      data: Object.values(
        experimentResults.prediction.prediction
      )[0].risk?.vol.map((v) => {
        return [v.timestamp, v.vol];
      }),
    });
  };

  const handlePrevious = () => {
    setTimelineLevel(timelineLevel - 1);
    if (timelineLevel === 1 || timelineLevel === 2) {
      window.postMessage({
        type: "historical_data",
        data: historicalData.hist_data,
      });
      window.postMessage({
        type: "historical_volatility",
        data: historicalData.hist_data_vol,
      });
      window.postMessage({
        type: "prediction_data",
        data: Object.values(experimentResults.prediction.prediction)[0]
          .prediction,
      });
      window.postMessage({
        type: "predicted_volatility",
        data: Object.values(
          experimentResults.prediction.prediction
        )[0].risk?.vol.map((v) => {
          return [v.timestamp, v.vol];
        }),
      });
      window.postMessage({
        type: "confidence_interval_data",
        data: Object.values(experimentResults.prediction.prediction)[0]
          .confidence_interval,
      });
      window.postMessage({
        type: "influence_data",
        data: factorsInfluence,
      });
    }
  };

  // save button and open modal
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleSave = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_PREDICTOR}/model-predictions/${predictionId}`,
        {
          name: predictionName,
          save: true,
        },
        {
          headers: {
            token: JSON.parse(localStorage.getItem("user")).token,
          },
        }
      );
      console.log("response PATCH:", response.data);
      if (response.status === 200) {
        setSuccessMessage("Your prediction have been saved.");
      } else {
        setErrorMessage("Something wrong happened, please try again later.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Header
            open={open}
            toggleDrawer={toggleDrawer}
            page="Indicator Builder"
          />
        </AppBar>
        <MenuBar open={open} Drawer={Drawer} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Timeline timelineLevel={timelineLevel} feature="Create Forecast"/>
          {timelineLevel === 0 ? (
            <CreatePrediction
              experimentParameters={experimentParameters}
              setExperimentParameters={setExperimentParameters}
              experimentResults={experimentResults}
              setExperimentResults={setExperimentResults}
              tickersList={tickersList}
              tickerId={tickerId}
              setTickerId={setTickerId}
              minDate={minDate}
              maxDate={maxDate}
              startDateInput={startDateInput}
              setStartDateInput={setStartDateInput}
              endDateInput={endDateInput}
              setEndDateInput={setEndDateInput}
              explicativeFactorsInput={explicativeFactorsInput}
              setExplicativeFactorsInput={setExplicativeFactorsInput}
              learningWindow={learningWindow}
              setLearningWindow={setLearningWindow}
              horizonInput={horizonInput}
              setHorizonInput={setHorizonInput}
              defaultValue={defaultValue}
              setDefaultValue={setDefaultValue}
              query={query}
              setQuery={setQuery}
              highestPredictedReturn={highestPredictedReturn}
              lowestPredictedReturn={lowestPredictedReturn}
              expectedVar={expectedVar}
              expectedVol={expectedVol}
              decrementHorizon={decrementHorizon}
              incrementHorizon={incrementHorizon}
              lastTimestamp={lastTimestamp}
              handleDownloadCsv={handleDownloadCsv}
              handleDownloadPerf={handleDownloadPerf}
              isLoading={isLoading}
              handleNext={handleNext}
              factorsInfluence={factorsInfluence}
              setFactorsInfluence={setFactorsInfluence}
              predictionId={predictionId}
            />
          ) : timelineLevel === 1 && causalModels ? (
            <PredictonModel
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              tickerId={tickerId}
              causalModels={causalModels}
            />
          ) : (
            timelineLevel === 2 &&
            predictionId && (
              <Deploy
                open={openModal}
                handleSave={handleSave}
                handleClose={handleClose}
                handleOpen={handleOpen}
                successMessage={successMessage}
                errorMessage={errorMessage}
                handlePrevious={handlePrevious}
                setPredictionName={setPredictionName}
              />
            )
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Forecast;
