import * as React from "react";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import Title from "./Title";

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 0.5,
        m: 0.5,
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#101010" : "grey.100",
        color: (theme) =>
          theme.palette.mode === "dark" ? "grey.300" : "grey.800",
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "grey.800" : "grey.300",
        borderRadius: 2,
        fontSize: "0.9rem",
        fontWeight: "100",
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const IndicatorPart2 = ({ indicator }) => {
  return (
    <React.Fragment>
      <Title>Explicative Factors</Title>
      <Typography
        component="p"
        // variant="h4"
      >
        {/* <div style={{ width: "100%",  height:"100%", backgroundColor:"red" }}> */}
          <Box
          className='scrollBar'
            sx={{
              display: "flex",
              flexWrap: "wrap",
              bgcolor: "background.paper",
              maxWidth: 300,
              borderRadius: 1,
              height:"200px", 
              overflowY:"scroll"
            }}
          >
            {/* {indicator.factorsList.map((factor, index) => {
              return <Item key={index}>{factor}</Item>;
            })} */}
          </Box>
        {/* </div> */}
      </Typography>
      {/* <Typography color="text.secondary" sx={{ flex: 1 }}>
        on 15 March, 2019
      </Typography> */}
      <div>
        {/* <Link color="primary" href="#" onClick={preventDefault}>
          View balance
        </Link> */}
      </div>
    </React.Fragment>
  );
};

export default IndicatorPart2;
