import React from "react";

const Timeline = ({ timelineLevel, feature }) => {
  return (
    <div className="timeline-container">
      <div>
        <div
          className="square"
          style={{
            backgroundColor: timelineLevel === 0 ? "#2D5596" : "#C4C4C4",
          }}
        >
          1
        </div>
        <div style={{ color: timelineLevel === 0 ? "#2D5596" : "#C4C4C4", wordBreak:"break-all" }}>
          {feature}
        </div>
      </div>
      <div className="line"></div>
      <div>
        <div
          className="square"
          style={{
            backgroundColor: timelineLevel === 1 ? "#2D5596" : "#C4C4C4",
          }}
        >
          2
        </div>
        <div style={{ color: timelineLevel === 1 ? "#2D5596" : "#C4C4C4", wordBreak:"break-all" }}>
          View Model
        </div>
      </div>
      <div className="line"></div>
      <div>
        <div
          className="square"
          style={{
            backgroundColor: timelineLevel === 2 ? "#2D5596" : "#C4C4C4",
          }}
        >
          3
        </div>
        <div style={{ color: timelineLevel === 2 ? "#2D5596" : "#C4C4C4", wordBreak:"break-all" }}>
          Deploy
        </div>
      </div>
    </div>
  );
};

export default Timeline;
