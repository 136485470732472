// Import libraries
import React, { useState, useEffect } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Papa from "papaparse";
import axios from "axios";

// Import components
import Header from "../components/Header";
import MenuBar from "../components/MenuBar";
import TemplateFile from "../components/TemplateFile";
import Table from "../components/Table";
import IndicatorButton from "../components/IndicatorButton";

// Import API
import fetchDatasources from "../API/fetchDatasources";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

const Datasources = () => {
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [datasourcesList, setDatasourcesList] = useState(undefined);
  const FileDownload = require("js-file-download");

  // GET datasources : First call done when this component is mounted
  useEffect(async () => {
    const response = await fetchDatasources();
    console.log("response from GET datasources:", response.data);
    setDatasourcesList(response.data);
  }, []);

  // POST datasource csv
  const addDatasource = async (event) => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    // https://medium.com/how-to-react/how-to-parse-or-read-csv-files-in-reactjs-81e8ee4870b0
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: async function (results) {
        const formData = new FormData();
        console.log("files:", event.target.files[0]);
        formData.append("files", event.target.files[0]);
        formData.append("name", event.target.files[0].name)

        formData.append("dateAsTimestamp", "yes");
        formData.append("timestampUnit", "s");
        formData.append("timestampColumn", "timestamp");
        formData.append("useCategory", "yes");

        const response = await axios.post(
          `${process.env.REACT_APP_URL_MASTER}/datasources`,
          formData,
          {
            headers: {
              token: JSON.parse(localStorage.getItem("user")).token,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("response CSV upload:", response);
        // if response status is 201 (Created) then a new datasource is created (id sent in response):
        if(response.status === 201){
          const newDatasource = response.data;
          console.log([...datasourcesList, newDatasource])
          setDatasourcesList([...datasourcesList, newDatasource])
        }
        // if response status 404 (Not Found) then there has been a problem and the datasource hasn't been created
      },
    });
  };

  const handleDelete = async (datasourceId) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_URL_MASTER}/datasources/${datasourceId}`,
      {
        headers: {
          token: JSON.parse(localStorage.getItem("user")).token,
        },
      }
    );
    if (response.status === 204) {
      setDatasourcesList([
        ...datasourcesList.filter(
          (datasource) => datasource.id !== datasourceId
        ),
      ]);
    }else{
      // ! Faut gérer l'erreur ici
    }
  };  

  const handleDownload = async(datasourceId)=>{
    const response = await axios.get(
      `${process.env.REACT_APP_URL_MASTER}/datasources/${datasourceId}?csvDownload=yes`,
      {
        headers: {
          token: JSON.parse(localStorage.getItem("user")).token,
        },
        responseType: "blob",
      }
    );
    FileDownload(response.data, "datapart.csv");
    console.log("response de download:", response.data);
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Header open={open} toggleDrawer={toggleDrawer} page="Datasources" />
        </AppBar>
        <MenuBar open={open} Drawer={Drawer} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8} lg={9}>
                {/* Component responsible of adding New datasources */}
                <IndicatorButton addDatasource={addDatasource} text="New Datasource"/>
              </Grid>
              <Grid item xs={12} md={8} lg={9}>
                <TemplateFile icon={<FileDownloadIcon />} />
              </Grid>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  {/* Component responsible of displaying list of datasources */}
                  {/* This component will mount every time its props changes : newDatasource is added to the datasources list*/}
                  <Table datasourcesList={datasourcesList} handleDelete={handleDelete} handleDownload={handleDownload}/>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
export default Datasources;
