import React from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import Title from "./Title";
import SaveModal from "./SaveModal";
import SelectList from "./SelectList";
import StartDate from "./DatePicker";
import EndDate from "./DatePicker";
import GroupedList from "./GroupedList";
import DropDownList from "./DropDownList";
import BasicSelect from "./BasicSelect";
import IndicatorButton from "./IndicatorButton";
import RadioButton from "./RadioButton";
import Slider from "./Slider";

const ConfigurationInputs = ({
  handleSubmit,
  // handleCreateModel,
  datasourcesList,
  datasourceId,
  setDatasourceId,
  startDateInput,
  setStartDateInput,
  endDateInput,
  setEndDateInput,
  minDate,
  maxDate,
  explicativeFactorsInput,
  setExplicativeFactorsInput,
  targetsList,
  targetId,
  setTargetId,
  returnPeriod,
  setReturnPeriod,
  trainingWindow,
  setTrainingWindow,
  rareness,
  setRareness,
  indicatorId,
  // setIndicatorName,
  optimizationValue,
  setOptimizationValue,
  successMessage,
  errorMessage,
  submitButtonClicked,
  setSubmitButtonClicked,
  setFirstDisplay,
}) => {
  console.log("indicatorId:", indicatorId);
  return (
    <React.Fragment>
      <Title>Input Fields</Title>
      <Divider />
      <br />
      <br />
      {targetId && (
        <Grid container spacing={24}>
          <Grid item xs sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IndicatorButton
              buttonClicked={submitButtonClicked}
              setButtonClicked={setSubmitButtonClicked}
              createIndicator={handleSubmit}
              text="APPLY"
            />
          </Grid>
        </Grid>
      )}
      <RadioButton value={optimizationValue} setValue={setOptimizationValue} />
      <br />
      <br />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <Grid container item spacing={6}>
            {/* Datasources List */}
            {datasourcesList && (
              <Grid item xs={4}>
                <SelectList
                  label="Datasource"
                  list={datasourcesList}
                  itemChosenId={datasourceId}
                  setItemChosenId={setDatasourceId}
                  setFirstDisplay={setFirstDisplay}
                />
              </Grid>
            )}

            {/* DatePicker */}
            <Grid item xs={4}>
              {startDateInput && (
                <StartDate
                  text="Start Date"
                  date={startDateInput}
                  handleChange={(newValue) => {
                    setFirstDisplay(false);
                    console.log("newValue:", newValue.getTime());
                    setStartDateInput(newValue.getTime());
                    window.postMessage({
                      type: "reset-indicator",
                    });
                    window.postMessage({
                      type: "start-date-update",
                      data: newValue.getTime(),
                    });
                  }}
                  minDate={minDate}
                  maxDate={maxDate}
                  fullWidth
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {endDateInput && (
                <EndDate
                  text="End Date"
                  date={endDateInput}
                  handleChange={(newValue) => {
                    setFirstDisplay(false);
                    console.log("newValue:", newValue);
                    console.log("getTime:", newValue.getTime());
                    setEndDateInput(newValue.getTime());
                    window.postMessage({
                      type: "reset-indicator",
                    });
                    window.postMessage({
                      type: "end-date-update",
                      data: newValue.getTime(),
                    });
                  }}
                  minDate={minDate}
                  maxDate={maxDate}
                  fullWidth
                />
              )}
            </Grid>
          </Grid>
          <Grid container item spacing={6}>
            {targetsList && (
              <Grid item xs={4}>
                <GroupedList
                  targetsList={targetsList}
                  setTargetId={setTargetId}
                  targetId={targetId}
                  setFirstDisplay={setFirstDisplay}
                />
              </Grid>
            )}
            {targetId && (
              <Grid item xs={4}>
                <BasicSelect
                  name="Training Window"
                  query={trainingWindow}
                  setQuery={setTrainingWindow}
                  handleChange={(value) => {
                    // console.log("training window change")
                    // setFirstDisplay(false);
                    window.postMessage({
                      type: "reset-indicator",
                    });
                    setTrainingWindow(value);
                  }}
                  fullWidth
                />
              </Grid>
            )}

            {targetId && (
              <Grid item xs={4}>
                <BasicSelect
                  name="Return Period"
                  query={returnPeriod}
                  setQuery={setReturnPeriod}
                  handleChange={(value) => {
                    // console.log("return period change")
                    // setFirstDisplay(false);
                    window.postMessage({
                      type: "reset-indicator",
                    });
                    setReturnPeriod(value);
                  }}
                  fullWidth
                />
              </Grid>
            )}
          </Grid>

          <Grid container item spacing={6}>
            {targetId && (
              <Grid item xs={4}>
                <BasicSelect
                  name="Rareness"
                  query={rareness}
                  setQuery={setRareness}
                  max={30}
                  handleChange={(value) => {
                    // console.log("rareness change")
                    // setFirstDisplay(false);
                    window.postMessage({
                      type: "reset-indicator",
                    });
                    setRareness(value);
                  }}
                  fullWidth
                />
              </Grid>
            )}
            {explicativeFactorsInput && targetId && (
              <Grid item xs={8}>
                <DropDownList
                  texts={{ placeholder: "Search Explicative Factors" }}
                  onChange={(newData) => {
                    console.log("explicative factors change");
                    setFirstDisplay(false);
                    window.postMessage({
                      type: "reset-indicator",
                    });
                    setExplicativeFactorsInput(newData);
                  }}
                  className="bootstrap-demo"
                  // showDropdown="initial"
                  hierarchical={false}
                  showPartiallySelected={true}
                  data={explicativeFactorsInput
                    .filter((factor) => factor.value !== "timestamp")
                    .map((factor) => {
                      if (factor.value === targetId.split("_")[1]) {
                        return {
                          ...factor,
                          children: factor.children.map((chi) => {
                            return {
                              ...chi,
                              children: chi.children.map((chi2) => {
                                if (chi2.value === targetId) {
                                  return {
                                    ...chi2,
                                    active: false,
                                    checked: false,
                                  };
                                } else {
                                  return {
                                    ...chi2,
                                  };
                                }
                              }),
                            };
                          }),
                        };
                      } else {
                        return factor;
                      }
                    })}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default ConfigurationInputs;
