import * as React from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";

export default function FreeSolo({ tickersList, setTickerId }) {
  console.log("tickersList:", tickersList);
  
  const options = tickersList.map((option) => {
    return {
      category:
        option.category.charAt(0).toUpperCase() + option.category.slice(1),
      ...option,
    };
  });
  return (
    <Stack spacing={2} sx={{ width: 300 }}>
      <Autocomplete
        freeSolo
        id="grouped-demo"
        disableClearable
        onChange={(event, value) => {
          console.log('tickerId:', value)
          setTickerId(value.id);
          window.postMessage({
            type: "ticker info",
            data: value
          });
        }}
        options={options.sort(
          (a, b) => -b.category.localeCompare(a.category)
        )}
        groupBy={(option) => option.category}
        getOptionLabel={(option) => option.title}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Target Company Asset"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
    </Stack>
  );
}
