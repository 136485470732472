import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import BasicSelect from "./BasicSelect";
import EndDate from "./DatePicker";
import StartDate from "./DatePicker";
import RadioButtons from "./RadioButtons";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const VerticalTabs = ({
  startDateInput,
  setStartDateInput,
  endDateInput,
  experimentParameters,
  setExperimentParameters,
  setEndDateInput,
  minDate,
  maxDate,
  experimentResults,
  setLearningWindow,
  learningWindow,
  setDefaultValue,
  defaultValue,
  tickerId,
  query, 
  setQuery, 
  setHorizonInput, 
  setFactorsInfluence
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    console.log("VALUE:", newValue);
    setValue(newValue);
    if (newValue === 1) {
      setDefaultValue(60);
    } else {
      setDefaultValue(undefined);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab label="Training Window" {...a11yProps(0)} />
        <Tab label="Custom Window" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        {endDateInput && (
          <EndDate
            text="Reference Date"
            date={endDateInput}
            handleChange={(newValue) => {
              setExperimentParameters({
                ...(experimentParameters ?? {}),
                date_interval: {
                  start_date: startDateInput,
                  end_date: newValue.getTime(),
                  learning_window: learningWindow
                    ? learningWindow
                    : defaultValue,
                },
                id: experimentResults?.id,
              });
              setEndDateInput(newValue.getTime());
            }}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
        <RadioButtons
          setLearningWindow={setLearningWindow}
          setExperimentParameters={setExperimentParameters}
          experimentParameters={experimentParameters}
          startDateInput={startDateInput}
          endDateInput={endDateInput}
          experimentResults={experimentResults}
        />
        {tickerId && (
          <BasicSelect
            query={query}
            setQuery={setQuery}
            handleChange={(value) => {
              setHorizonInput(value);
            }}
            fullWidth
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {startDateInput && (
          <StartDate
            text="From"
            date={startDateInput}
            handleChange={(newValue) => {
              setExperimentParameters({
                ...(experimentParameters ?? {}),
                date_interval: {
                  start_date: newValue.getTime(),
                  end_date: endDateInput,
                },
                id: experimentResults?.id,
              });
              setStartDateInput(newValue.getTime());
            }}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
        {endDateInput && (
          <EndDate
            text="Reference Date"
            date={endDateInput}
            handleChange={(newValue) => {
              setExperimentParameters({
                ...(experimentParameters ?? {}),
                date_interval: {
                  start_date: startDateInput,
                  end_date: newValue.getTime(),
                },
                id: experimentResults?.id,
              });
              setEndDateInput(newValue.getTime());
            }}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
        {tickerId && (
          <BasicSelect
            query={query}
            setQuery={setQuery}
            handleChange={(value) => {
              setHorizonInput(value);
              setFactorsInfluence(null)
            }}
            fullWidth
          />
        )}
      </TabPanel>
    </Box>
  );
};

export default VerticalTabs;
