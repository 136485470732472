import React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

const MaterialUIPickers = ({
  text,
  date,
  handleChange,
  minDate,
  maxDate,
  fullWidth,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={text}
        inputFormat="dd/MM/yyyy"
        // minDate={new Date(minDate / 1000)}
        minDate={new Date(minDate)}
        maxDate={new Date(maxDate)}
        value={new Date(date)}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField {...params} fullWidth={fullWidth} />
        )}
        // loading={true}
        clearable
      />
    </LocalizationProvider>
  );
};

export default MaterialUIPickers;
