import React, { useEffect, useState, useContext, useCallback } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import chroma, { hsl } from "chroma-js";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

//Import contexts
import {
  CreatedModelContext,
  ClDropLayoutFactorsGlobalContext,
} from "../contexts/global_values";

//Import components
import PredictionLine from "../components/PredictionLine";
import Network from "../components/Network";
import ProbaTable from "../components/ProbaTable";
import LinearProgress from "../components/LinearProgress";

const red = "#f995aa";
const blue = "lightblue";
const gray = "gray";

const PredictionModel = ({
  handlePrevious,
  handleNext,
  tickerId,
  causalModels,
}) => {
  const { createdModelContextValue, setCreatedModelContextValue } =
    useContext(CreatedModelContext);
  const {
    clDropLayoutFactorsGlobalContextValue,
    setClDropLayoutFactorsGlobalContextValue,
  } = useContext(ClDropLayoutFactorsGlobalContext);

  const history = useHistory();

  const [nodeProbability, setNodeProbability] = useState([]);
  // state to manage the table popin on click on node
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [jsonData, setJsonData] = useState(null);
  const [jsonDataGlobal, setJsonDataGlobal] = useState(null);
  const [pickedFont, setPickedFont] = useState([
    "#f7fcf5",
    "#e5f5e0",
    "#c7e9c0",
    "#a1d99b",
    "#74c476",
    "#41ab5d",
    "#238b45",
    "#006d2c",
    "#00441b",
  ]);
  const [showColorscalePicker, setShowColorscalePicker] = useState(false);

  const [columnsGrouped, setColumnsGrouped] = useState();
  const [factorsGrouped, setFactorsGrouped] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const [nodeMaxEntropy, setNodeMaxEntropy] = useState(0);
  const [nodeMinEntropy, setNodeMinEntropy] = useState(0);
  const [edgeMaxEntropy, setEdgeMaxEntropy] = useState(0);
  const [edgeMinEntropy, setEdgeMinEntropy] = useState(0);
  const [stepEdgesSlider, setStepEdgesSlider] = useState(0);
  const [stepNodesSlider, setStepNodesSlider] = useState(0);

  const showNodeFunction = useCallback((nodeProbabilities) => {
    setNodeProbability(nodeProbabilities);
  }, []);

  useEffect(async () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    let res = causalModels.dag;
    console.log("RESPONSE:", res);
    res.edges.forEach((edge) => {
      edge.color = gray;
    });

    // get max width value
    const maxEntropy = Math.max.apply(
      Math,
      res.nodes.map(function (o) {
        return o.entropy;
      })
    );
    const minEntropy = Math.min.apply(
      Math,
      res.nodes.map(function (o) {
        return o.entropy;
      })
    );
    const maxEntropyEdge = Math.max.apply(
      Math,
      res.edges.map(function (o) {
        return o.entropy;
      })
    );
    const minEntropyEdge = Math.min.apply(
      Math,
      res.edges.map(function (o) {
        return o.entropy;
      })
    );
    // set min and max entropy's
    setEdgeMaxEntropy(maxEntropyEdge);
    setEdgeMinEntropy(minEntropyEdge);
    setNodeMinEntropy(minEntropy);
    setNodeMaxEntropy(maxEntropy);
    setStepEdgesSlider(
      [(maxEntropyEdge - minEntropyEdge) / res.edges.length] + 1
    );
    setStepNodesSlider([(maxEntropy - minEntropy) / res.nodes.length] + 1);
    // get E S G factors
    let E = res.nodes.find(
      (node) => node.name === "PortfolioEnvironmentalScore"
    );
    let S = res.nodes.find((node) => node.name === "PortfolioGovernanceScore");
    let G = res.nodes.find((node) => node.name === "PortfolioSocialScore");
    // star square diamond
    if (E) {
      E.shape = "star";
    }
    if (S) {
      S.shape = "star";
    }
    if (G) {
      G.shape = "star";
    }
    // star square diamond case upoload csv
    res.nodes.forEach((node) => {
      const f = clDropLayoutFactorsGlobalContextValue.find(
        (factor) => factor.name === node.name
      );
      if (f && f.isTarget) {
        node.shape = "star";
      }
    });

    res.nodes.forEach((node) => {
      node.label = node.name;
      //node.color=chroma.scale('puBuGn').domain([maxEntropy,0])(node.entropy).toString()
      node.color = chroma
        .scale(pickedFont)
        .domain([minEntropy, maxEntropy])(node.entropy)
        .toString();
      node.value = node.entropy;
    });
    const widthCalulWay = "entropys";
    res.edges.forEach((edge) => {
      if (widthCalulWay === "entropy") {
        edge.width = (edge.entropy / maxEntropyEdge) * 10;
        edge.label = edge.entropy;
        if (edge.corr > 0) {
          edge.color = blue;
        } else if (edge.corr < 0) {
          edge.color = red;
        }
      } else {
        if (edge.corr !== 0) {
          edge.width = Math.abs(edge.corr) * 10;
          edge.label = edge.corr;
          if (edge.corr > 0) {
            edge.color = blue;
          } else if (edge.corr < 0) {
            edge.color = red;
          }
        } else {
          edge.width = edge.width * 10;
          edge.label = edge.corr;
          if (edge.corr > 0) {
            edge.color = blue;
          } else if (edge.corr < 0) {
            edge.color = red;
          }
        }
      }
      if (!edge.corr) {
        edge.width = 1;
      }
    });
    setJsonData(res);
    setJsonDataGlobal(res);

    const test = _.groupBy(res.nodes, (c) => {
      if (c.label?.substr(0, c.label.indexOf(" "))) {
        return c.label?.substr(0, c.label.indexOf(" "));
      } else {
        return c.label;
      }
    });
    // add targets to test
    const targets = [];

    res.nodes.forEach((element) => {
      if (
        clDropLayoutFactorsGlobalContextValue.find(
          (factor) => factor.name === element.name && factor.isTarget
        )
      ) {
        targets.push(element);
        for (const key in test) {
          const targetFactor = test[key].find((el) => el.name === element.name);
          if (targetFactor) {
            test[key].splice(
              test[key].indexOf(targetFactor),
              test[key].indexOf(targetFactor) + 1
            );
          }
        }
      }
    });
    // if there is targets
    if (targets.length > 0) {
      test["Targets"] = targets;
    }

    setColumnsGrouped(test);
    let tab = [];
    for (const f in test) {
      test[f].forEach((e) => {
        tab.push(e.id);
      });
    }
    setFactorsGrouped(tab);
  }, [pickedFont]);

  // Mockup
  const filterNetworkData = (jsonData) => {
    console.log("jsonData:", jsonData);
    return {
      edges: jsonData.edges.filter(
        (edge) =>
          factorsGrouped.includes(edge.from) && factorsGrouped.includes(edge.to)
      ),
      nodes: jsonData.nodes.filter((node) => factorsGrouped.includes(node.id)),
    };
  };

  return (
    <Container
      maxWidth="xl"
      sx={{ mt: 4, mb: 4 }}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <ArrowBackIosIcon
        sx={{ fontSize: "50px", color: "#1976D2" }}
        onClick={handlePrevious}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              minHeight: 300,
            }}
          >
            {tickerId && <PredictionLine isLoading={isLoading} />}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              minHeight: 340,
            }}
          >
            <div className="network">
              {isLoading ? (
                <LinearProgress />
              ) : (
                <Network
                  model_name={createdModelContextValue.model_name}
                  networkData={filterNetworkData(jsonData)}
                  showNode={showNodeFunction}
                  setIsOpen={setIsOpen}
                  causalModels={causalModels}
                />
              )}
            </div>
          </Paper>
        </Grid>
        <ProbaTable
          open={isOpen}
          setIsOpen={setIsOpen}
          onClose={() => setIsOpen(false)}
          showNode={nodeProbability}
        />
      </Grid>
      <ArrowForwardIosIcon
        sx={{ fontSize: "50px", color: "#1976D2" }}
        onClick={handleNext}
      />
    </Container>
  );
};

export default PredictionModel;
