// import React from "react";
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
// import FormLabel from "@material-ui/core/FormLabel";

// const RadioButtonsGroup = ({ value, setValue }) => {

//   const handleClick = (event) => {
//     if (event.target.value === value) {
//       setValue("0");
//     } else {
//       setValue(event.target.value);
//     }
//   };

//   return (
//     <div>
//       <FormLabel component="legend">Optimization</FormLabel>
//       <RadioGroup aria-label="gender" name="gender1" value={value}>
//         <FormControlLabel
//           value="1"
//           control={<Radio onClick={handleClick} />}
//           // label="Optimization"
//         />
//       </RadioGroup>
//     </div>
//   );
// };

// export default RadioButtonsGroup;


import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const RowRadioButtonsGroup=({ value, setValue })=> {
    const handleChange = (event) => {
      window.postMessage({
        type: "reset-indicator",
      });
        setValue(event.target.value);
      };
  return (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Optimization</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel value="1" control={<Radio />} label="Optimized" />
        <FormControlLabel value="0" control={<Radio />} label="Default" />
      </RadioGroup>
    </FormControl>
  );
}

export default RowRadioButtonsGroup;
