import axios from "axios";

const fetchIndicators = async () => {
  return await axios.get(
    `${process.env.REACT_APP_URL_MASTER}/indicators`,
    {
      headers: {
        token: JSON.parse(localStorage.getItem("user")).token,
      },
    }
  );
}

export default fetchIndicators;