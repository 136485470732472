import React, {useState, useEffect} from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

const IndicatorButton = ({ buttonClicked, setButtonClicked, addDatasource, createIndicator, text }) => {

  useEffect(()=>{
    window.addEventListener(
      "message",
      (message) => {
        if(message.data.type === "reset-indicator"){
         setButtonClicked(false)
        }
      }
    )
  }, [])

  return (
    <Button
      variant={buttonClicked === true ? "contained" : "outlined"}
      startIcon={text === "New Datasource" && <AddIcon />}
      component="label"
      onClick={createIndicator}
    >
      {text}
      {addDatasource && (
        <input type="file" hidden accept=".csv" onChange={addDatasource} />
      )}
    </Button>
  );
};

export default IndicatorButton;
