import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useState } from "react";
import "./App.css";

import Forecast from "./containers/Forecast";
import Indicator from "./containers/Indicator";
import EditIndicator from "./containers/EditIndicator";
import Datasources from "./containers/Datasources";
import Dashboard from "./containers/Dashboard";
import UserProfile from "./authentification/UserProfile";
import Login from "./authentification/Login";

import { SocketContext, socket } from "./contexts/socket";
import {
  CreatedModelContext,
  ClDragLayoutFactorsContext,
  ClDropLayoutFactorsContext,
  ClDropLayoutFactorsGlobalContext,
} from "./contexts/global_values";
import { useAuth } from "./contexts/user";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEye,
  faEyeSlash,
  faAngleDown,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";

library.add(faEye, faEyeSlash, faAngleDown, faChartLine);

function App() {
  // console.log("useAuth:", useAuth)
  // console.log("currentUser:", currentUser)
  const { currentUser } = useAuth();

  const [createdModelContextValue, setCreatedModelContextValue] = useState({
    model_name: "modelName",
    description: "",
  });
  const [clDragLayoutFactorsContextValue, setClDragLayoutFactorsContextValue] =
    useState([]);
  const [clDropLayoutFactorsContextValue, setClDropLayoutFactorsContextValue] =
    useState([]);
  const [
    clDropLayoutFactorsGlobalContextValue,
    setClDropLayoutFactorsGlobalContextValue,
  ] = useState([]);

  return currentUser ? (
    <div className="App">
      <SocketContext.Provider value={socket}>
        <ClDropLayoutFactorsGlobalContext.Provider
          value={{
            clDropLayoutFactorsGlobalContextValue,
            setClDropLayoutFactorsGlobalContextValue,
          }}
        >
          <ClDragLayoutFactorsContext.Provider
            value={{
              clDragLayoutFactorsContextValue,
              setClDragLayoutFactorsContextValue,
            }}
          >
            <ClDropLayoutFactorsContext.Provider
              value={{
                clDropLayoutFactorsContextValue,
                setClDropLayoutFactorsContextValue,
              }}
            >
              <CreatedModelContext.Provider
                value={{
                  createdModelContextValue,
                  setCreatedModelContextValue,
                }}
              >
                <Router>
                  {/* {currentUser && <NavigationBar />}
          {currentUser && <UserProfile />} */}
                  {currentUser ? (
                    <Switch>
                      <Route path="/indicators/:id">
                        <EditIndicator />
                      </Route>
                      <Route path="/create-indicator">
                        <Indicator />
                      </Route>
                      <Route path="/datasources">
                        <Datasources />
                      </Route>
                      <Route path="/create-forecast">
                        <Forecast />
                      </Route>
                      <Route path="/">
                        <Dashboard />
                      </Route>
                    </Switch>
                  ) : (
                    <Route path="/login">
                      <Login />
                    </Route>
                  )}
                </Router>
              </CreatedModelContext.Provider>
            </ClDropLayoutFactorsContext.Provider>
          </ClDragLayoutFactorsContext.Provider>
        </ClDropLayoutFactorsGlobalContext.Provider>
      </SocketContext.Provider>
    </div>
  ) : (
    <Login />
  );
}

export default App;
