import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const BasicSelect = ({  query, setQuery, handleChange, fullWidth }) => {
  useEffect(() => {
    const timeOutId = setTimeout(() => handleChange(query), 900);
    return () => clearTimeout(timeOutId);
  }, [query]);

  return (
    <Box sx={{ minWidth: 120 }}>
      <TextField
        value={query}
        id="outlined-number"
        type="number"
        InputProps={{
          inputProps: { 
              max: 21, min: 0
          }
      }}
        defaultValue={0}
        onChange={(event) => setQuery(event.target.value)}
        fullWidth={fullWidth}
      />
    </Box>
  );
};

export default BasicSelect;
