import React, { useEffect } from "react";
import _ from "lodash";

import highchartsMore from "highcharts/highcharts-more";
const Highcharts = require("highcharts/highstock");
highchartsMore(Highcharts);

Highcharts.theme = {
  time: {
    useUTC: false,
  },
  colors: [
    "#058DC7",
    "#50B432",
    "#ED561B",
    "#DDDF00",
    "#24CBE5",
    "#64E572",
    "#FF9655",
    "#FFF263",
    "#6AF9C4",
  ],
  chart: {
    backgroundColor: {
      linearGradient: [0, 0, 500, 500],
      stops: [
        [0, "rgb(255, 255, 255)"],
        [1, "rgb(240, 240, 255)"],
      ],
    },
  },
  title: {
    style: {
      color: "#000",
      font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
    },
  },
  subtitle: {
    style: {
      color: "#666666",
      font: 'bold 12px "Trebuchet MS", Verdana, sans-serif',
    },
  },
  legend: {
    itemStyle: {
      font: "9pt Trebuchet MS, Verdana, sans-serif",
      color: "black",
    },
    itemHoverStyle: {
      color: "gray",
    },
  },
};

// Apply the theme
Highcharts.setOptions(Highcharts.theme);

const DisplayChart = ({
  isLoading,
  indicatorData,
  targetData,
  targetName
}) => {
  console.log("targetData:", targetData);
  console.log("indicatorData:", indicatorData);
  useEffect(() => {
    const createChart = () => {
      const chart = Highcharts.stockChart("container-indicator", {
        title: {
          text: targetName,
        },
        navigator: {
          adaptToUpdatedData: true,
          series: {
            fillOpacity: 0.7,
          },
        },
        plotOptions: {
          series: {
            showInNavigator: true,
            dataGrouping: {
              enabled: false,
            },
          },
        },
        rangeSelector: {
          enabled: true,
          selected: 5,
        },
        legend: {
          enabled: true,
        },
        subtitle: {
          align: "right",
          x: -10,
        },
        tooltip: {
          useHTML: true,
          headerFormat: "<span>{point.x:%d %b %Y}</span><table>",
          pointFormat:
            "<h3 style='color:{series.color}'>{series.name}</h3><span>Value: </span><b>{point.y}</b> <br/><span>Confidence Level: </span><b>{point.confidenceLevel}%</b> <br/>",
          footerFormat: "</table>",
          followPointer: true,
          valueDecimals: 2,
          xDateFormat: "<b>%d/%m/%Y</b>",
          shared: true,
        },
        xAxis: {
          type: "datetime",
        },
        yAxis: [
          // Primary yAxis
          {
            labels: {
              format: "{value} $",
              style: {
                color: Highcharts.getOptions().colors[4],
              },
            },
            title: {
              text: "Price",
              style: {
                color: Highcharts.getOptions().colors[4],
              },
            },
            opposite: false,
            ordinal: false,
          },
          {
            // Secondary yAxis
            min: -1,
            max: 1.5,
            gridLineWidth: 0,
            title: {
              text: "Indicator",
              style: {
                color: Highcharts.getOptions().colors[5],
              },
            },
            labels: {
              format: "{value}",
              style: {
                color: Highcharts.getOptions().colors[5],
              },
            },
          },
        ],
        series: [
          // 0
          {
            name: "Target Company Asset",
            data: targetData,
            id: "target_data",
            type: "line",
            tooltip: {
              useHTML: true,
              headerFormat: "<span>{point.x:%d %b %Y}</span><table>",
              pointFormat:
                "<h3 style='color:{series.color}'>{series.name}</h3><span>Value: </span><b>{point.y} $</b> <br/>",
              footerFormat: "</table>",
            },
            color: Highcharts.getOptions().colors[4],
            opacity: 1,
          },
          // 1
          {
            name: "Indicator Data",
            data: indicatorData,
            tooltip: {
              useHTML: true,
              headerFormat: "<span>{point.x:%d %b %Y}</span><table>",
              pointFormat:
                "<h3 style='color:{series.color}'>{series.name}</h3><span>Value: </span><b>{point.y}</b>",
              footerFormat: "</table>",
            },
            id: "indicator_data",
            type: "line",
            color: Highcharts.getOptions().colors[5],
            opacity: 2,
            yAxis: 1,
          },
        ],
      });
      return chart;
    };
    createChart();
  }, [indicatorData, targetData]);

  return (
    <div
      id="container-indicator"
      style={{ height: isLoading ? "570px" : "400px", width: "100%" }}
    ></div>
  );
};

export default DisplayChart;
