import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const ComboBox = ({
  label,
  list,
  setItemChosenId,
  setItemChosenLabel,
  itemChosenId,
  setFirstDisplay,
}) => {
  const data = list.map((data) => ({
    id: data.id,
    label: data.name,
  }));
  
  const defaultValue = data.filter((d) => d.id === itemChosenId)

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={data}
      defaultValue={itemChosenId ? defaultValue[0].label : ""}
      renderInput={(params) => <TextField {...params} label={label} />}
      onChange={(event, value) => {
        console.log("selectList change");
        setFirstDisplay(false);
        window.postMessage({
          type: "reset-indicator",
        });
        window.postMessage({
          type: "reset-inputs",
        });
        if (setItemChosenId) {
          setItemChosenId(value.id);
        } else {
          setItemChosenLabel(value.label);
        }
      }}
    />
  );
};
export default ComboBox;
