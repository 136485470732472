import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";

import Deploy from "./Deploy";
import IndicatorModel from "./IndicatorModel";
import CreateIndicator from "./CreateIndicator";
import Timeline from "../components/Timeline";
import MenuBar from "../components/MenuBar";
import Header from "../components/Header";

// Import API
import fetchDatasources from "../API/fetchDatasources";

const mdTheme = createTheme();

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const Indicator = () => {
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [timelineLevel, setTimelineLevel] = useState(0);
  const [datasourcesList, setDatasourcesList] = useState(undefined);
  const [targetsList, setTargetsList] = useState(undefined);
  const [datasourceId, setDatasourceId] = useState(undefined);
  const [targetId, setTargetId] = useState(undefined);
  const [startDateInput, setStartDateInput] = useState(undefined);
  const [endDateInput, setEndDateInput] = useState(undefined);
  const [minDate, setMinDate] = useState(undefined);
  const [maxDate, setMaxDate] = useState(undefined);
  const [explicativeFactorsInput, setExplicativeFactorsInput] =
    useState(undefined);
  const [returnPeriod, setReturnPeriod] = useState(21);
  const [rareness, setRareness] = useState(10);
  const [trainingWindow, setTrainingWindow] = useState(180);

  const [indicatorId, setIndicatorId] = useState(undefined);
  const [indicatorName, setIndicatorName] = useState(undefined);
  const [targetData, setTargetData] = useState(undefined);
  const [indicatorData, setIndicatorData] = useState(undefined);
  const [factorsInfluence, setFactorsInfluence] = useState(undefined);
  const [optimizationValue, setOptimizationValue] = useState("1");
  const [duration, setDuration] = useState(undefined);

  // translate server messages to user
  const [requestStatus, setRequestStatus] = useState(undefined);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const assignObjectPaths = (obj, stack) => {
    Object.keys(obj).forEach((k) => {
      const node = obj[k];
      if (typeof node === "object") {
        node.path = stack ? `${stack}.${k}` : k;
        assignObjectPaths(node, node.path);
      }
    });
  };

  const [causalModels, setCausalModels] = useState(undefined);
  const [timestampChosen, setTimestampChosen] = useState(undefined);

  // In case you've been directed from datasources page to here
  let location = useLocation();
  useEffect(() => {
    if (location) {
      setDatasourceId(location.state);
    }
  }, [location]);

  // GET datasources : First call done when this component is mounted
  useEffect(async () => {
    const response = await fetchDatasources();
    console.log("response from GET datasources:", response.data);
    setDatasourcesList(response.data);
  }, []);

  // Get targets every time datasourceId changes
  useEffect(async () => {
    if (datasourceId) {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_MASTER}/datasources/${datasourceId}`,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("user")).token,
          },
        }
      );

      console.log("GET datasources/id:", response.data);

      setTargetsList(response.data.factors);
      assignObjectPaths(response.data.explicativeFactors);
      setExplicativeFactorsInput(response.data.explicativeFactors);

      setMinDate(parseInt(response.data.dateInterval.min_ts));
      setStartDateInput(parseInt(response.data.dateInterval.startDate_ts));
      setMaxDate(parseInt(response.data.dateInterval.max_ts));
      setEndDateInput(parseInt(response.data.dateInterval.endDate_ts));
    }
  }, [datasourceId]);

  useEffect(async () => {
    if (
      (targetId && datasourceId && timelineLevel === 0) ||
      (targetId && datasourceId && timelineLevel === 1)
    ) {
      const category = targetId.split("_")[1];
      const column = targetId.split("_")[2];

      const response = await axios.get(
        `${process.env.REACT_APP_URL_MASTER}/datasources/${datasourceId}?category=${category}&column=${column}`,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("user")).token,
          },
        }
      );
      setTargetData(
        response.data.map((d) => {
          return [d.timestamp, d.value];
        })
      );
    }
  }, [targetId, timelineLevel]);

  useEffect(() => {
    if (targetData) {
      console.log("target data updated");
      window.postMessage({
        type: "target_data",
        data: targetData,
      });
    }
  }, [targetData]);

  useEffect(() => {
    if (targetData) {
      window.postMessage({
        type: "target_info",
        data: targetId.split("_")[2],
      });
    }
  }, [targetData]);

  useEffect(async () => {
    if (
      (indicatorId && timelineLevel === 0) ||
      (indicatorId && timelineLevel === 1)
    ) {
      let indicatorValues = null;
      let factorsInfluenceValues = null;
      let summary = null;
      while (
        (indicatorValues === null || indicatorValues === undefined) &&
        (factorsInfluenceValues === null ||
          factorsInfluenceValues === undefined) &&
        (summary === null || summary === undefined)
      ) {
        console.log("indicatorData:", indicatorData);
        await new Promise((r) => setTimeout(r, 1000));
        const response = await axios.get(
          `${process.env.REACT_APP_URL_MASTER}/indicators/${indicatorId}`,
          {
            headers: {
              token: JSON.parse(localStorage.getItem("user")).token,
            },
          }
        );
        // console.log("Indicator:", response.data);
        indicatorValues = response.data.indicatorData;
        console.log("indicatorValues:", indicatorValues);
        factorsInfluenceValues = response.data.influence;
        console.log("Request Status:", response.data.status);
        setRequestStatus(response.data.status);
        setDuration(response.data.duration);
        summary = response.data.summary;
      }
      setIndicatorData(
        indicatorValues.map((d) => {
          return [d.timestamp, d.value];
        })
      );
      console.log("tara");
      setFactorsInfluence(factorsInfluenceValues);
      setCausalModels(summary.models);
    }
  }, [indicatorId, timelineLevel]);

  useEffect(() => {
    if (causalModels) {
      setTimestampChosen(Object.keys(causalModels)[0]);
    }
  }, [causalModels]);

  useEffect(() => {
    if (indicatorData) {
      console.log("indicator data updated");
      window.postMessage({
        type: "indicator_data",
        data: indicatorData,
      });
    }
  }, [indicatorData]);

  useEffect(() => {
    if (factorsInfluence) {
      console.log("influence data updated");
      window.postMessage({
        type: "influence_data",
        data: factorsInfluence,
      });
    }
  }, [factorsInfluence]);

  useEffect(() => {
    if (factorsInfluence) {
      window.postMessage({
        type: "target_info",
        data: targetId.split("_")[2],
      });
    }
  }, [factorsInfluence]);

  const handleSubmit = async () => {
    const transformedFactors = explicativeFactorsInput
      .filter((factor) => factor.value !== "timestamp")
      .map((factor) => {
        if (factor.value === targetId.split("_")[1]) {
          return {
            ...factor,
            children: factor.children.map((chi) => {
              return {
                ...chi,
                children: chi.children.map((chi2) => {
                  if (chi2.value === targetId) {
                    return {
                      ...chi2,
                      active: false,
                      checked: false,
                    };
                  } else {
                    return {
                      ...chi2,
                    };
                  }
                }),
              };
            }),
          };
        } else {
          return factor;
        }
      });
    setSubmitButtonClicked(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL_MASTER}/indicators`,
        {
          datasourceId: datasourceId,
          target: targetId.split("_")[2],
          explicativeFactors: transformedFactors,
          returnPeriod: returnPeriod,
          dateInterval: {
            startDate: startDateInput,
            endDate: endDateInput,
          },
          dateAsTimestamp: "yes",
          timestampUnit: "ms",
          trainingWindow: trainingWindow,
          rareness: rareness,
          optimization: parseInt(optimizationValue),
        },
        {
          headers: {
            token: JSON.parse(localStorage.getItem("user")).token,
          },
        }
      );
      // console.log(response.data);
      if (response.data.status !== 201) {
        setIndicatorId(response.data.indicatorId);
      } else {
        setRequestStatus("failed");
      }
    } catch (error) {
      console.log(error);
      setRequestStatus("failed");
    }
  };

  const handleNext = () => {
    setTimelineLevel(timelineLevel + 1);
    if (timelineLevel === 0 || timelineLevel === 1) {
      window.postMessage({
        type: "target_data",
        data: targetData.filter(
          (point) => point[0] > startDateInput && point[0] < endDateInput
        ),
      });
      window.postMessage({
        type: "indicator_data",
        data: indicatorData,
      });
    }
  };

  const handlePrevious = () => {
    setTimelineLevel(timelineLevel - 1);
    if (timelineLevel === 0 || timelineLevel === 1) {
      window.postMessage({
        type: "target_data",
        data: targetData.filter(
          (point) => point[0] > startDateInput && point[0] < endDateInput
        ),
      });
      window.postMessage({
        type: "indicator_data",
        data: indicatorData,
      });
    }
  };

  // save button and open modal
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleSave = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_URL_MASTER}/indicators/${indicatorId}`,
        {
          name: indicatorName,
          save: true,
        },
        {
          headers: {
            token: JSON.parse(localStorage.getItem("user")).token,
          },
        }
      );
      console.log("response PATCH:", response);
      if (response.status === 200) {
        setSuccessMessage("Your indicator have been saved.");
      } else {
        setErrorMessage("Something wrong happened, please try again later.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener("message", (message) => {
      if (message.data.type === "navigator_update") {
        console.log(message.data.type, message.data.data);
        setStartDateInput(message.data.data[0]);
        setEndDateInput(message.data.data[1]);
      }
    });
  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Header
            open={open}
            toggleDrawer={toggleDrawer}
            page="Indicator Builder"
          />
        </AppBar>
        <MenuBar open={open} Drawer={Drawer} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Timeline timelineLevel={timelineLevel} feature="Create Indicator"/>
          {timelineLevel === 0 ? (
            <CreateIndicator
              datasourcesList={datasourcesList}
              datasourceId={datasourceId}
              setDatasourceId={setDatasourceId}
              startDateInput={startDateInput}
              setStartDateInput={setStartDateInput}
              endDateInput={endDateInput}
              setEndDateInput={setEndDateInput}
              minDate={minDate}
              maxDate={maxDate}
              targetsList={targetsList}
              targetId={targetId}
              setTargetId={setTargetId}
              explicativeFactorsInput={explicativeFactorsInput}
              setExplicativeFactorsInput={setExplicativeFactorsInput}
              returnPeriod={returnPeriod}
              setReturnPeriod={setReturnPeriod}
              trainingWindow={trainingWindow}
              setTrainingWindow={setTrainingWindow}
              rareness={rareness}
              setRareness={setRareness}
              optimizationValue={optimizationValue}
              setOptimizationValue={setOptimizationValue}
              duration={duration}
              requestStatus={requestStatus}
              successMessage={successMessage}
              errorMessage={errorMessage}
              submitButtonClicked={submitButtonClicked}
              setSubmitButtonClicked={setSubmitButtonClicked}
              handleNext={handleNext}
              // handleCreateModel={handleCreateModel}
              handleSubmit={handleSubmit}
              indicatorId={indicatorId}
              indicatorData={indicatorData}
              factorsInfluence={factorsInfluence}
            />
          ) : timelineLevel === 1 && causalModels ? (
            <IndicatorModel
              handleSubmit={handleSubmit}
              requestStatus={requestStatus}
              indicatorData={indicatorData}
              duration={duration}
              causalModels={causalModels}
              timestampChosen={timestampChosen}
              setTimestampChosen={setTimestampChosen}
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              timelineLevel
            />
          ) : (
            timelineLevel === 2 &&
            indicatorId && (
              <Deploy
                open={openModal}
                handleSave={handleSave}
                handleClose={handleClose}
                handleOpen={handleOpen}
                successMessage={successMessage}
                errorMessage={errorMessage}
                handlePrevious={handlePrevious}
                setIndicatorName={setIndicatorName}
              />
            )
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Indicator;
