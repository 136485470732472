import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Import components
import LinearProgress from "../components/LinearProgress";
import ConfigurationInputs from "../components/ConfigurationInputs";
import LineChart from "../components/LineChart";
import Heatmap from "../components/Heatmap";

// Import assets
import error from "../assets/error.PNG";

const CreateIndicator = ({
  datasourcesList,
  datasourceId,
  setDatasourceId,
  startDateInput,
  setStartDateInput,
  endDateInput,
  setEndDateInput,
  minDate,
  maxDate,
  targetsList,
  targetId,
  setTargetId,
  explicativeFactorsInput,
  setExplicativeFactorsInput,

  returnPeriod,
  setReturnPeriod,
  trainingWindow,
  setTrainingWindow,
  rareness,
  setRareness,
  optimizationValue,
  setOptimizationValue,

  duration,
  requestStatus,
  successMessage,
  errorMessage,
  buttonClicked,
  setButtonClicked,
  handleNext,
  handleCreateModel,
  handleSubmit,

  indicatorId,
  indicatorData,

  factorsInfluence,
  setTimestampChosen,
}) => {
  const [firstDisplay, setFirstDisplay] = useState(true);

  return requestStatus === "failed" ? (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={error} alt="error illustration" />
      <div>Something went wrong, please try again.</div>
    </div>
  ) : (
    <Container
      maxWidth="xl"
      sx={{ mt: 4, mb: 4 }}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              minHeight: 340,
            }}
          >
            <ConfigurationInputs
              handleSubmit={handleSubmit}
              // handleCreateModel={handleCreateModel}
              datasourcesList={datasourcesList}
              datasourceId={datasourceId}
              setDatasourceId={setDatasourceId}
              targetsList={targetsList}
              startDateInput={startDateInput}
              setStartDateInput={setStartDateInput}
              endDateInput={endDateInput}
              setEndDateInput={setEndDateInput}
              minDate={minDate}
              maxDate={maxDate}
              explicativeFactorsInput={explicativeFactorsInput}
              setExplicativeFactorsInput={setExplicativeFactorsInput}
              setTargetId={setTargetId}
              returnPeriod={returnPeriod}
              setReturnPeriod={setReturnPeriod}
              targetId={targetId}
              trainingWindow={trainingWindow}
              setTrainingWindow={setTrainingWindow}
              rareness={rareness}
              setRareness={setRareness}
              indicatorId={indicatorId}
              // setIndicatorName={setIndicatorName}
              optimizationValue={optimizationValue}
              setOptimizationValue={setOptimizationValue}
              successMessage={successMessage}
              errorMessage={errorMessage}
              buttonClicked={buttonClicked}
              setButtonClicked={setButtonClicked}
              setFirstDisplay={setFirstDisplay}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} sx={{ mt: 4, mb: 4 }}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              // height: 240,
            }}
          >
            {(requestStatus === "doing" || requestStatus === "todo") &&
              indicatorData !== null && <LinearProgress />}
            <br />
            <br />
            {duration && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <div>Duration:&nbsp;</div>
                <strong>{duration} sec</strong>
              </div>
            )}
            <LineChart setTimestampChosen={setTimestampChosen} />
          </Paper>
        </Grid>

        <Grid item xs={12} sx={{ mt: 4, mb: 4 }}>
          {(requestStatus === "doing" || requestStatus === "todo") &&
          factorsInfluence !== null ? (
            <LinearProgress />
          ) : (
            requestStatus === "succeeded" &&
            factorsInfluence && (
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  // height: 540,
                }}
              >
                <Heatmap factorsInfluence={factorsInfluence}/>
              </Paper>
            )
          )}
        </Grid>
      </Grid>
      {indicatorData && (
        <ArrowForwardIosIcon
          sx={{ fontSize: "50px", color: "#1976D2" }}
          onClick={handleNext}
        />
      )}
    </Container>
  );
};

export default CreateIndicator;
