import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
// libraries importation
import { Network } from "vis-network/standalone/esm/vis-network";

var changeChosenLabelColor = function (values, id, selected, hovering) {
  values.color = "#FFFFFF";
  values.size += 1;
  values.face = "serif";
  values.mod = "bold italic";
  values.strokeWidth = 5;
  values.strokeColor = "#000000";
};

const options = {
  autoResize: true,
  height: "100%",
  width: "100%",
  clickToUse: false,

  layout: {
    // this would make the tree directed
    hierarchical: {
      enabled: true,
      levelSeparation: 75,
      nodeSpacing: 100,
      direction: "UD",
      edgeMinimization: false,
      parentCentralization: false,
      sortMethod: "directed",
      shakeTowards: "leaves",
      blockShifting: true,
    },
    randomSeed: 2,
    improvedLayout: true,
    clusterThreshold: 100,
  },
  interaction: {
    tooltipDelay: 10,
    selectConnectedEdges: true,
    hoverConnectedEdges: true,
    hover: true,
    hideEdgesOnDrag: true,
    hideNodesOnDrag: false,
    dragView: true,
    dragNodes: true,
    navigationButtons: true,
    keyboard: {
      enabled: false,
      speed: { x: 10, y: 10, zoom: 0.02 },
      bindToWindow: true,
    },
    zoomSpeed: 1,
    zoomView: true,
  },
  nodes: {
    size: 18,
    borderWidth: 2,
    borderWidthSelected: 3,
    chosen: {
      label: changeChosenLabelColor,
    },
    //image, circularImage, diamond, dot, star, triangle, triangleDown, hexagon, square and icon.
    shape: "hexagon",
    scaling: {
      label: {
        min: 8,
        max: 20,
      },
    },
  },
  edges: {
    smooth: true,
    arrows: {
      to: true,
    },
  },

  physics: {
    hierarchicalRepulsion: {
      centralGravity: 0,
      avoidOverlap: 0.5,
    },
    solver: "hierarchicalRepulsion",
    adaptiveTimestep: true,
    enabled: true,
  },
};

const VisNetwork = ({
  networkData,
  causalModels,
  timestampChosen,
  model_name,
  showNode,
  setIsOpen,
}) => {
  // A reference to the div rendered by this component
  const domNode = useRef(null);

  // A reference to the vis network instance
  const network = useRef(null);

  const [dataNetwork, setDataNetwork] = useState({});

  useEffect(() => {
    setDataNetwork(networkData);
  }, [networkData]);

  useEffect(() => {
    // interactions with the network
    network.current = new Network(domNode.current, dataNetwork, options);
    // click event on the network
    if (network.current) {
      network.current.on("click", function (properties) {
        let node = properties["nodes"]["0"];
        if (network.current.body.nodes[node]) {
          if (model_name) {
            const nodeInNetwork = dataNetwork.nodes.find(
              (e) => e.id === properties.nodes[0]
            );
            console.log("nodeInNetwork:", nodeInNetwork);
            if(timestampChosen){
              showNodeFunction(causalModels[timestampChosen].cpts, nodeInNetwork);
            }else{
              showNodeFunction(causalModels.cpts, nodeInNetwork);
            }
           

          }
        }
      });
    }
  }, [dataNetwork]);

  function showNodeFunction(data, nodeInNetwork) {
    console.log("data:", data);
    console.log("data:", data[nodeInNetwork.name]);
    console.log("table:", data[nodeInNetwork.name].table);
    showNode(data[nodeInNetwork.name].table);
    setIsOpen(true);
  }

  return (
    <React.Fragment>
      <div style={{ height: "700px" }} ref={domNode} />
    </React.Fragment>
  );
};

export default VisNetwork;
