import React from "react";
import CircularLoader from "./CircularLoader";

const Metrics = ({
  isLoading,
  highestPredictedReturn,
  lowestPredictedReturn,
  expectedVar,
  expectedVol,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      {/* <div
        style={{
          border: "1px solid #494D58",
          width: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "inherit",
          alignItems: "center",
          height: "100px",
        }}
      >
        <div className="risk-metric">Confidence Score</div>

        <div style={{ color: "#FFA500", fontWeight: "600" }}>
          {(confidenceScore*100).toFixed(2)} %
        </div>
      </div> */}
      {isLoading || !expectedVar ? (
           <div className="circular-loader-container"><CircularLoader color="secondary"/></div>
      ) : (
        <div
          className="form-container-30-son"
        >
          <div className="risk-metric">Return</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              width:"90%"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontWeight: "200",
                  fontSize: "14px",
                  textAlign: "center",
                  // lineHeight: "inherit"
                }}
              >
                Lowest Return*
              </p>
              <div style={{ color: "#FFA500", fontWeight: "500", fontSize:"30px", marginTop:"30px" }}>
                {(lowestPredictedReturn * 100).toFixed(2)} %
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontWeight: "200",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                Highest Return*
              </p>
              <div style={{ color: "#FFA500", fontWeight: "500", fontSize:"30px", marginTop:"30px" }}>
                {(highestPredictedReturn * 100).toFixed(2)} %
              </div>
            </div>
          </div>
        </div>
      )}
       {isLoading || (!highestPredictedReturn && !lowestPredictedReturn) ? (
           <div className="circular-loader-container"><CircularLoader color="success"/></div>
      ) : (
      <div className="form-container-30-son">
        <div className="risk-metric">Expected VaR</div>
        <p
          style={{
            fontWeight: "200",
            fontSize: "14px",
            textAlign: "center"
          }}
        >
          last day of prediction*
        </p>
        <div style={{ color: "#FFA500", fontWeight: "500", fontSize:"30px", marginTop:"30px" }}>
          {expectedVar.toFixed(2)}
        </div>
      </div>
      )}
       {isLoading || !expectedVol ? (
           <div className="circular-loader-container"><CircularLoader color="inherit"/></div>
      ) : (
      <div className="form-container-30-son">
        <div className="risk-metric">Expected Volatility</div>
        <p
          style={{
            fontWeight: "200",
            fontSize: "14px",
            textAlign: "center",
          }}
        >
          last day of prediction*
        </p>
        <div style={{ color: "#FFA500", fontWeight: "500", fontSize:"30px", marginTop:"30px" }}>
          {expectedVol.toFixed(2)}
        </div>
      </div>
      )}
    </div>
  );
};

export default Metrics;
