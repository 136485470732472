import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-router-dom";

export const mainListItems = (
  <React.Fragment>
    <Link to="/">
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
    </Link>
    <Link to="/create-indicator">
      <ListItemButton>
        <ListItemIcon>
          <ControlCameraIcon />
        </ListItemIcon>
        <ListItemText primary="Indicator Builder" />
      </ListItemButton>
    </Link>
    <Link to="/create-forecast">
      <ListItemButton>
        <ListItemIcon>
          <FontAwesomeIcon icon="chart-line" />
        </ListItemIcon>
        <ListItemText primary="Forecast" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <Link to="/datasources">
      <ListItemButton>
        <ListItemIcon>
          <StorageOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Datasources" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);
