import React from "react";

import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import SaveModal from "../components/SaveModal";

const Deploy = ({
  open,
  handleOpen,
  handleSave,
  handleClose,
  successMessage,
  errorMessage,
  handlePrevious,
  setIndicatorName,
  setPredictionName
}) => {
  return (
      <Container
        maxWidth="xl"
        sx={{ mt: 4, mb: 4 }}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ArrowBackIosIcon
          sx={{ fontSize: "50px", color: "#1976D2" }}
          onClick={handlePrevious}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ mt: 4, mb: 4 }}>
              <Button sx={{width:100}} onClick={handleOpen}>Save</Button>
              <SaveModal
                open={open}
                handleClose={handleClose}
                handleSave={handleSave}
                setIndicatorName={setIndicatorName}
                setPredictionName={setPredictionName}
                successMessage={successMessage}
                errorMessage={errorMessage}
              />
          </Grid>
        </Grid>
      </Container>
  );
};

export default Deploy;
