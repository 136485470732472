import React, { useState, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import icons
import scalnyx from "../assets/logo-scalnyx.png";
import logo from "../assets/projector.png";
import lock from "../assets/lock.svg";

// import contexts
import { useAuth } from "../contexts/user";

const Login = () => {
  const [errorMsg, setErrorMsg] = useState("");
  const [eye, setEye] = useState(false);
  const { sign_in } = useAuth();
  const email = useRef();
  const password = useRef();

  // functions
  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      if (email.current.value.length && password.current.value.length) {
        await sign_in(email.current.value, password.current.value);
      } else {
        setErrorMsg("Vous n'avez pas rempli tous les champs.");
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401 || 404) {
        setErrorMsg("L'adresse mail et/ou mot de passe ne sont pas valide.");
      } else {
        alert("Erreur");
      }
    }
  };

  return (
    <div className="login-container">
      <div className="marketing-section">
        <img style={{ width: "50%" }} src={scalnyx} at="logo scalnyx" />
        <img src={logo} alt="logo esg" />
        <p>Power intelligence in your models with causal AI</p>
      </div>
      <div className="login-section">
        <div className="form-login-bloc">
          <h2>Welcome ! Enter below your credentials</h2>
          <p className="error">{errorMsg}</p>
          <form>
            <div>
              <label className="label">Username</label>
              <div className="inputWithIcon">
                <input
                  className="form-input-with-icon"
                  type="text"
                  ref={email}
                />
              </div>
            </div>
            <div>
              <label className="label">Password</label>
              <div className="inputWithIcon">
                <input
                  className="form-input-with-icon"
                  id="input"
                  type="password"
                  ref={password}
                />
                {/* <img className="lock-icon" src={lock} alt="lock-icon" /> */}
                <div className="inputIcon">
                  {eye === true ? (
                    <FontAwesomeIcon
                      id="eye"
                      icon="eye"
                      onClick={() => {
                        setEye(false);
                        var x = document.getElementById("input");
                        x.type = "text";
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      id="eye"
                      icon="eye-slash"
                      onClick={() => {
                        setEye(true);
                        var x = document.getElementById("input");
                        x.type = "password";
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <button onClick={handleSignIn} className="signin-btn" type="submit">
              Sign in
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
