import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import AutocompleteInput from "../components/AutocompleteInput";
import PredictionLine from "../components/PredictionLine";
import LinearProgress from "../components/LinearProgress";
import Heatmap from "../components/Heatmap";
import Metrics from "../components/Metrics";
import DownloadButton from "../components/DownloadButton";
import VerticalTabs from "../components/VerticalTabs";
import DropDownList from "../components/DropDownList";

const CreatePrediction = ({
  experimentParameters,
  setExperimentParameters,
  experimentResults,
  tickersList,
  tickerId,
  setTickerId,
  minDate,
  maxDate,
  startDateInput,
  setStartDateInput,
  endDateInput,
  setEndDateInput,
  explicativeFactorsInput,
  setExplicativeFactorsInput,
  learningWindow,
  setLearningWindow,
  horizonInput,
  setHorizonInput,
  defaultValue,
  setDefaultValue,
  query,
  setQuery,
  highestPredictedReturn,
  lowestPredictedReturn,
  expectedVar,
  expectedVol,
  handleDownloadCsv,
  isLoading,
  predictionId,
  handleNext,
  factorsInfluence,
  setFactorsInfluence
}) => {
  return (
    <Container
      maxWidth="xl"
      sx={{ mt: 4, mb: 4 }}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              minHeight: 300,
            }}
          >
            {tickersList && (
              <>
                <h3>Asset</h3>
                <AutocompleteInput
                  tickersList={tickersList}
                  setTickerId={setTickerId}
                />
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              minHeight: 300,
            }}
          >
            {tickerId && minDate && maxDate && (
              <>
                <h3>Model Training Data</h3>
                <VerticalTabs
                  minDate={minDate}
                  maxDate={maxDate}
                  startDateInput={startDateInput}
                  setStartDateInput={setStartDateInput}
                  endDateInput={endDateInput}
                  setEndDateInput={setEndDateInput}
                  experimentParameters={experimentParameters}
                  setExperimentParameters={setExperimentParameters}
                  experimentResults={experimentResults}
                  learningWindow={learningWindow}
                  setLearningWindow={setLearningWindow}
                  setDefaultValue={setDefaultValue}
                  defaultValue={defaultValue}
                  tickerId={tickerId}
                  query={query}
                  setQuery={setQuery}
                  setHorizonInput={setHorizonInput}
                  setFactorsInfluence={setFactorsInfluence}
                />
              </>
            )}
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            {explicativeFactorsInput && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h3>Explicative Factors</h3>
                  <DownloadButton handleDownloadCsv={handleDownloadCsv} />
                </div>
                <br />

                <DropDownList
                  texts={{ placeholder: "Search" }}
                  onChange={(newData) => {
                    setExplicativeFactorsInput(newData);
                    setExperimentParameters({
                      ...(experimentParameters ?? {}),
                      explicative_factors: newData,
                      id: experimentResults?.id,
                    });
                  }}
                  className="mdl-demo"
                  showDropdown="initial"
                  hierarchical={false}
                  showPartiallySelected={true}
                  data={explicativeFactorsInput}
                />
              </div>
            )}
          </Paper>
        </Grid>

        <Grid item xs={8}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              {tickerId && horizonInput && (
                <div style={{ width: "100%" }}>
                  <Metrics
                    isLoading={isLoading}
                    highestPredictedReturn={highestPredictedReturn}
                    lowestPredictedReturn={lowestPredictedReturn}
                    expectedVar={expectedVar}
                    expectedVol={expectedVol}
                  />
                </div>
              )}
              {tickerId && <PredictionLine isLoading={isLoading} />}
            </div>
          </Paper>
        </Grid>
        {factorsInfluence === null ? (
          <LinearProgress />
        ) : (
          factorsInfluence && (
            <Grid item xs={12} sx={{ mt: 4, mb: 4 }}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  // height: 540,
                }}
              >
                <Heatmap factorsInfluence={factorsInfluence}/>
              </Paper>
            </Grid>
          )
        )}
      </Grid>
      {predictionId && (
        <ArrowForwardIosIcon
          sx={{ fontSize: "50px", color: "#1976D2" }}
          onClick={handleNext}
        />
      )}
    </Container>
  );
};

export default CreatePrediction;
