import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";

// Import components
import Header from "../components/Header";
import MenuBar from "../components/MenuBar";
import LinearProgress from "../components/LinearProgress";
import FailedRequest from "../components/FailedRequest";
import ConfigurationInputs from "../components/ConfigurationInputs";
import DisplayChart from "../components/DisplayChart";
import DisplayHeatmap from "../components/DisplayHeatmap";
import LineChart from "../components/LineChart";
import Heatmap from "../components/Heatmap";

// Import assets
import error from "../assets/error.PNG";

// Import API
import fetchDatasources from "../API/fetchDatasources";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

const EditIndicator = () => {
  const [firstDisplay, setFirstDisplay] = useState(true);
  
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const location = useLocation();
  const [optimizationValue, setOptimizationValue] = useState(undefined);
  const [datasourceId, setDatasourceId] = useState(undefined);
  const [startDateInput, setStartDateInput] = useState(undefined);
  const [endDateInput, setEndDateInput] = useState(undefined);
  const [minDate, setMinDate] = useState(undefined);
  const [maxDate, setMaxDate] = useState(undefined);
  const [targetsList, setTargetsList] = useState(undefined);
  const [targetId, setTargetId] = useState(undefined);
  const [explicativeFactorsInput, setExplicativeFactorsInput] =
    useState(undefined);
  const [trainingWindow, setTrainingWindow] = useState(undefined);
  const [returnPeriod, setReturnPeriod] = useState(undefined);
  const [rareness, setRareness] = useState(undefined);
  const [duration, setDuration] = useState(undefined);
  const [targetData, setTargetData] = useState(undefined);
  const [indicatorId, setIndicatorId] = useState(undefined);
  const [indicatorName, setIndicatorName] = useState(undefined);
  const [indicatorData, setIndicatorData] = useState(undefined);
  const [factorsInfluence, setFactorsInfluence] = useState(undefined);

  const [openModal, setOpenModal] = useState(false);

  // translate server messages to user
  const [requestStatus, setRequestStatus] = useState(undefined);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [buttonClicked, setButtonClicked] = useState(false);

  const assignObjectPaths = (obj, stack) => {
    Object.keys(obj).forEach((k) => {
      const node = obj[k];
      if (typeof node === "object") {
        node.path = stack ? `${stack}.${k}` : k;
        assignObjectPaths(node, node.path);
      }
    });
  };

  useEffect(() => {
    console.log(location.pathname);
    console.log(location.state);
    console.log(_.isEmpty(location.state));

    setIndicatorId(location.state.indicatorId);
    setOptimizationValue(location.state.optimization);
    setDatasourceId(location.state.datasourceId);
    setStartDateInput(location.state.startDate);
    setEndDateInput(location.state.endDate);
    setMinDate(location.state.min);
    setMaxDate(location.state.max);
    setTargetsList(location.state.factors);
    setTargetId(
      location.state.factors.filter(
        (f) => f.title === location.state.targetName
      )[0].id
    );
    setExplicativeFactorsInput(location.state.explicativeFactors);
    setTrainingWindow(location.state.trainingWindow);
    setReturnPeriod(location.state.returnPeriod);
    setRareness(location.state.rareness);
    setDuration(location.state.duration);

    setTargetData(location.state.targetData);
    setIndicatorData(location.state.indicatorData);
    setFactorsInfluence(location.state.influence);
  }, [location]);

  const [datasourcesList, setDatasourcesList] = useState(undefined);

  // GET datasources : First call done when this component is mounted
  useEffect(async () => {
    const response = await fetchDatasources();
    console.log("response from GET datasources:", response.data);
    setDatasourcesList(response.data);
  }, []);

  // const [requestStatus, setRequestStatus] = useState(undefined);
  // const [duration, setDuration] = useState(undefined);
  // const [indicatorData, setIndicatorData] = useState(undefined);
  // const [factorsInfluence, setFactorsInfluence] = useState(undefined);

  // useEffect(async () => {
  //   if (indicatorId !== undefined) {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_URL_MASTER}/indicators/${indicatorId}`,
  //       {
  //         headers: {
  //           token: JSON.parse(localStorage.getItem("user")).token,
  //         },
  //       }
  //     );
  //     setRequestStatus(response.data.status);
  //     setDuration(response.data.duration);
  //     setIndicatorData(
  //       response.data.indicatorData.map((d) => {
  //         return [d.timestamp, d.value];
  //       })
  //     );
  //     setFactorsInfluence(response.data.influence);
  //   }
  // }, [indicatorId]);

  // Get targets every time datasourceId changes
  useEffect(async () => {
    if (datasourceId && !firstDisplay) {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_MASTER}/datasources/${datasourceId}`,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("user")).token,
          },
        }
      );

      console.log("GET datasources/id:", response.data);

      setTargetsList(response.data.factors);
      assignObjectPaths(response.data.explicativeFactors);
      setExplicativeFactorsInput(response.data.explicativeFactors);

      setMinDate(parseInt(response.data.dateInterval.min_ts));
      setStartDateInput(parseInt(response.data.dateInterval.startDate_ts));
      setMaxDate(parseInt(response.data.dateInterval.max_ts));
      console.log("Test:", response.data.dateInterval.startDate_ts);
      setEndDateInput(parseInt(response.data.dateInterval.endDate_ts));
    }
  }, [datasourceId]);

  useEffect(async () => {
    if (
      targetId &&
      datasourceId &&
      startDateInput &&
      endDateInput &&
      !firstDisplay
    ) {
      const category = targetId.split("_")[1];
      const column = targetId.split("_")[2];

      const response = await axios.get(
        `${process.env.REACT_APP_URL_MASTER}/datasources/${datasourceId}?category=${category}&column=${column}`,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("user")).token,
          },
        }
      );
      setTargetData(
        response.data
          .map((d) => {
            return [d.timestamp, d.value];
          })
          .filter(
            (point) =>
              startDateInput / 1000 <= point[0] &&
              point[0] <= endDateInput / 1000
          )
      );
    }
  }, [targetId]);

  useEffect(() => {
    if (targetData && !firstDisplay) {
      console.log("target data updated");
      window.postMessage({
        type: "target_data",
        data: targetData,
      });
    }
  }, [targetData]);

  useEffect(() => {
    if (targetData && !firstDisplay) {
      window.postMessage({
        type: "target_info",
        data: targetId.split("_")[2],
      });
    }
  }, [targetData]);

  useEffect(async () => {
    if (indicatorId && !firstDisplay) {
      let indicatorValues = null;
      let factorsInfluenceValues = null;
      while (
        (indicatorValues === null || indicatorValues === undefined) &&
        (factorsInfluenceValues === null ||
          factorsInfluenceValues === undefined)
      ) {
        console.log("indicatorData:", indicatorData);
        await new Promise((r) => setTimeout(r, 1000));
        const response = await axios.get(
          `${process.env.REACT_APP_URL_MASTER}/indicators/${indicatorId}`,
          {
            headers: {
              token: JSON.parse(localStorage.getItem("user")).token,
            },
          }
        );
        // console.log("Indicator:", response.data);
        indicatorValues = response.data.indicatorData;
        console.log("indicatorValues:", indicatorValues);
        factorsInfluenceValues = response.data.influence;
        setRequestStatus(response.data.status);
        setDuration(response.data.duration);
      }
      setIndicatorData(
        indicatorValues.map((d) => {
          return [d.timestamp, d.value];
        })
      );
      setFactorsInfluence(factorsInfluenceValues);
    }
  }, [indicatorId]);

  useEffect(() => {
    if (indicatorData && !firstDisplay) {
      console.log("indicator data updated");
      window.postMessage({
        type: "indicator_data",
        data: indicatorData,
      });
    }
  }, [indicatorData]);

  useEffect(() => {
    if (factorsInfluence && !firstDisplay) {
      console.log("influence data updated");
      window.postMessage({
        type: "influence_data",
        data: factorsInfluence,
      });
    }
  }, [factorsInfluence]);

  useEffect(() => {
    if (factorsInfluence && !firstDisplay) {
      window.postMessage({
        type: "target_info",
        data: targetId.split("_")[2],
      });
    }
  }, [factorsInfluence]);

  const handleSubmit = async () => {
    console.log("submit button")
    setFirstDisplay(false);
    const transformedFactors = explicativeFactorsInput
      .filter((factor) => factor.value !== "timestamp")
      .map((factor) => {
        if (factor.value === targetId.split("_")[1]) {
          return {
            ...factor,
            children: factor.children.map((chi) => {
              return {
                ...chi,
                children: chi.children.map((chi2) => {
                  if (chi2.value === targetId) {
                    return {
                      ...chi2,
                      active: false,
                      checked: false,
                    };
                  } else {
                    return {
                      ...chi2,
                    };
                  }
                }),
              };
            }),
          };
        } else {
          return factor;
        }
      });
    setButtonClicked(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL_MASTER}/indicators`,
        {
          datasourceId: datasourceId,
          target: targetId.split("_")[2],
          explicativeFactors: transformedFactors,
          returnPeriod: returnPeriod,
          dateInterval: {
            startDate: startDateInput,
            endDate: endDateInput,
          },
          dateAsTimestamp: "yes",
          timestampUnit: "s",
          trainingWindow: trainingWindow,
          rareness: rareness,
          optimization: parseInt(optimizationValue),
        },
        {
          headers: {
            token: JSON.parse(localStorage.getItem("user")).token,
          },
        }
      );
      // console.log(response.data);
      if (response.data.status !== 201) {
        setIndicatorId(response.data.indicatorId);
      } else {
        setRequestStatus("failed");
      }
    } catch (error) {
      console.log(error);
      setRequestStatus("failed");
    }
  };

  // save button and open modal
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleSave = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_URL_MASTER}/indicators/${indicatorId}`,
        {
          name: indicatorName,
          save: true,
        },
        {
          headers: {
            token: JSON.parse(localStorage.getItem("user")).token,
          },
        }
      );
      console.log("response PATCH:", response.data);
      if (response.data.status === "success") {
        setSuccessMessage("Your indicator have been saved.");
      } else {
        setErrorMessage("Something wrong happened, please try again later.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Header
            open={open}
            toggleDrawer={toggleDrawer}
            page="Indicator Builder"
          />
        </AppBar>
        <MenuBar open={open} Drawer={Drawer} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          {requestStatus === "failed" ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={error} alt="error illustration" />
              <div>Something went wrong, please try again.</div>
            </div>
          ) : (
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      minHeight: 340,
                    }}
                  >
                    {
                    // optimizationValue &&
                      datasourcesList &&
                      datasourceId &&
                      // startDateInput &&
                      // endDateInput && 
                      (
                        <ConfigurationInputs
                          handleSubmit={handleSubmit}
                          handleOpen={handleOpen}
                          open={openModal}
                          handleClose={handleClose}
                          handleSave={handleSave}
                          datasourcesList={datasourcesList}
                          datasourceId={datasourceId}
                          setDatasourceId={setDatasourceId}
                          targetsList={targetsList}
                          targetId={targetId}
                          setTargetId={setTargetId}
                          startDateInput={startDateInput}
                          setStartDateInput={setStartDateInput}
                          endDateInput={endDateInput}
                          setEndDateInput={setEndDateInput}
                          minDate={minDate}
                          maxDate={maxDate}
                          explicativeFactorsInput={explicativeFactorsInput}
                          setExplicativeFactorsInput={
                            setExplicativeFactorsInput
                          }
                          returnPeriod={returnPeriod}
                          setReturnPeriod={setReturnPeriod}
                          trainingWindow={trainingWindow}
                          setTrainingWindow={setTrainingWindow}
                          rareness={rareness}
                          setRareness={setRareness}
                          indicatorId={indicatorId}
                          setIndicatorName={setIndicatorName}
                          optimizationValue={optimizationValue}
                          setOptimizationValue={setOptimizationValue}
                          successMessage={successMessage}
                          errorMessage={errorMessage}
                          buttonClicked={buttonClicked}
                          setButtonClicked={setButtonClicked}
                          setFirstDisplay={setFirstDisplay}
                        />
                      )}
                  </Paper>
                </Grid>

                <Grid item xs={12} sx={{ mt: 4, mb: 4 }}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      // height: 240,
                    }}
                  >
                    {(requestStatus === "doing" || requestStatus === "todo") &&
                      indicatorData !== null && <LinearProgress />}
                    <br />
                    <br />
                    {duration && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <div>Duration:&nbsp;</div>
                        <strong>{duration} sec</strong>
                      </div>
                    )}
                    {targetData && indicatorData && firstDisplay ? (
                      <DisplayChart
                        targetData={targetData}
                        indicatorData={indicatorData}
                      />
                    ) : (
                      targetData &&
                      indicatorData &&
                      !firstDisplay && <LineChart />
                    )}
                  </Paper>
                </Grid>

                <br />
                <br />

                <Grid item xs={12} sx={{ mt: 4, mb: 4 }}>
                  {(requestStatus === "doing" || requestStatus === "todo") &&
                  factorsInfluence !== null ? (
                    <LinearProgress />
                  ) : (
                    // requestStatus === "succeeded" &&
                    factorsInfluence && (
                      <Paper
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          // height: 540,
                        }}
                      >
                        {firstDisplay ? (
                          <DisplayHeatmap
                            influence={factorsInfluence}
                          />
                        ) : (
                          <Heatmap />
                        )}
                      </Paper>
                    )
                  )}
                </Grid>
              </Grid>
              <Copyright sx={{ pt: 4 }} />
            </Container>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default EditIndicator;
