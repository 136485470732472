import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const Grouped = ({ targetsList, setTargetId, targetId, setFirstDisplay }) => {
  const options = targetsList.map((option) => {
    return {
      category:
        option.category.charAt(0).toUpperCase() + option.category.slice(1),
      ...option,
    };
  });
  const target = targetsList.filter((t) => t.id === targetId)[0];

  return (
    <Autocomplete
      id="grouped-demo"
      disableClearable
      defaultValue={target}
      onChange={(event, value) => {
        console.log("grouped List change")
        setFirstDisplay(false);
        console.log("value:", value)
        setTargetId(value.id);
        window.postMessage({
          type: "reset-indicator",
        });
        // window.postMessage({
        //   type: "ticker info",
        //   data: value
        // });
      }}
      options={options.sort((a, b) => -b.category.localeCompare(a.category))}
      groupBy={(option) => option.category}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Target Company Asset"
          InputProps={{
            ...params.InputProps,
            type: "search",
          }}
        />
      )}
    />
  );
};

export default Grouped;
