import React, { useState, useEffect } from "react";
import _ from "lodash";

import highchartsMore from "highcharts/highcharts-more";
const Highcharts = require("highcharts/highstock");
highchartsMore(Highcharts);

Highcharts.theme = {
  time: {
    useUTC: false,
  },
  colors: [
    "#058DC7",
    "#50B432",
    "#ED561B",
    "#DDDF00",
    "#24CBE5",
    "#64E572",
    "#FF9655",
    "#FFF263",
    "#6AF9C4",
  ],
  chart: {
    backgroundColor: {
      linearGradient: [0, 0, 500, 500],
      stops: [
        [0, "rgb(255, 255, 255)"],
        [1, "rgb(240, 240, 255)"],
      ],
    },
  },
  title: {
    style: {
      color: "#000",
      font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
    },
  },
  subtitle: {
    style: {
      color: "#666666",
      font: 'bold 12px "Trebuchet MS", Verdana, sans-serif',
    },
  },
  legend: {
    itemStyle: {
      font: "9pt Trebuchet MS, Verdana, sans-serif",
      color: "black",
    },
    itemHoverStyle: {
      color: "gray",
    },
  },
};

// Apply the theme
Highcharts.setOptions(Highcharts.theme);

const PredictionLine = ({ setTimestampChosen, isLoading }) => {
  let initialLine = [];
  let startDateInput;
  let endDateInput;

  const [navigatorExtremes, setNavigatorExtremes] = useState(undefined);

  const createChart = () => {
    const chart = Highcharts.stockChart("container-indicator", {
      plotOptions: {
        series: {
          showInNavigator: true,
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                setTimestampChosen(this.category);
              },
            },
          },
        },
      },
      navigator: {
        series: {
          name: "Indicator Data",
        },
      },
      rangeSelector: {
        enabled: true,
        selected: 5,
      },
      legend: {
        enabled: true,
      },
      subtitle: {
        align: "right",
        x: -10,
      },
      tooltip: {
        useHTML: true,
        headerFormat: "<span>{point.x:%d %b %Y}</span><table>",
        pointFormat:
          "<h3 style='color:{series.color}'>{series.name}</h3><span>Value: </span><b>{point.y}</b> <br/><span>Confidence Level: </span><b>{point.confidenceLevel}%</b> <br/>",
        footerFormat: "</table>",
        followPointer: true,
        valueDecimals: 2,
        xDateFormat: "<b>%d/%m/%Y</b>",
        shared: true,
      },
      xAxis: {
        events: {
          afterSetExtremes: function () {
            var series = this.series[0],
              points = series.points,
              dataMin = points[0].x,
              dataMax = points[points.length - 1].x;
            // console.log(dataMin, dataMax);
            setNavigatorExtremes([dataMin, dataMax]);
          },
        },
        type: "datetime",
      },
      yAxis: [
        // Primary yAxis
        {
          labels: {
            format: "{value} $",
            style: {
              color: Highcharts.getOptions().colors[4],
            },
          },
          title: {
            text: "Price",
            style: {
              color: Highcharts.getOptions().colors[4],
            },
          },
          opposite: false,
          ordinal: false,
        },
        {
          // Secondary yAxis
          min: -1,
          max: 1.5,
          gridLineWidth: 0,
          title: {
            text: "Indicator",
            style: {
              color: Highcharts.getOptions().colors[5],
            },
          },
          labels: {
            format: "{value}",
            style: {
              color: Highcharts.getOptions().colors[5],
            },
          },
        },
      ],
      series: [
        // 0
        {
          name: "Target Company Asset",
          data: [],
          id: "target_data",
          type: "line",
          tooltip: {
            useHTML: true,
            headerFormat: "<span>{point.x:%d %b %Y}</span><table>",
            pointFormat:
              "<h3 style='color:{series.color}'>{series.name}</h3><span>Value: </span><b>{point.y} $</b> <br/>",
            footerFormat: "</table>",
          },
          color: Highcharts.getOptions().colors[4],
          opacity: 1,
        },
        // 1
        {
          name: "Indicator Data",
          data: [],
          tooltip: {
            useHTML: true,
            headerFormat: "<span>{point.x:%d %b %Y}</span><table>",
            pointFormat:
              "<h3 style='color:{series.color}'>{series.name}</h3><span>Value: </span><b>{point.y}</b>",
            footerFormat: "</table>",
          },
          id: "indicator_data",
          type: "line",
          color: Highcharts.getOptions().colors[5],
          opacity: 2,
          yAxis: 1,
        },
      ],
    });
    return chart;
  };

  let chart;

  const onMessage = () => {
    if (startDateInput || endDateInput) {
      console.log("date changed");
      let newChart;
      if (startDateInput) {
        console.log("FIRST");
        newChart = initialLine;
        // .filter((point) => point[0] > startDateInput);
        console.log("newChart:", newChart);
      }
      if (endDateInput) {
        console.log("SECOND");
        newChart = initialLine;
        // .filter((point) => point[0] < endDateInput);
        console.log("newChart:", newChart);
      }
      const prices = newChart.map((point) => {
        return point[1];
      });
      console.log("prices:", prices);
      const minPrice = _.min(prices);
      const maxPrice = _.max(prices);
      console.log(minPrice, maxPrice);

      chart.get("target_data").setData(newChart, true);
      chart.yAxis[0].setExtremes(minPrice, maxPrice);
      chart.redraw();
    } else {
      // without changing dates
      console.log("initialLine:", initialLine);
      const prices = initialLine.map((point) => {
        return point[1];
      });
      console.log("prices:", prices);
      const minPrice = _.min(prices);
      const maxPrice = _.max(prices);
      console.log(minPrice, maxPrice);

      chart.get("target_data").setData([...initialLine], true);
      chart.yAxis[0].setExtremes(minPrice, maxPrice);
      chart.redraw();
    }
  };

  // No parameters passing here or the component will refresh
  useEffect(() => {
    chart = createChart();
    const handleMessage = (message) => {
      if (message.data.type === "target_data") {
        console.log("target_data:", message.data.data);
        initialLine = [...message.data.data];
        onMessage();
      } else if (message.data.type === "indicator_data") {
        console.log("indicator_data:", message.data.data);
        chart.get("indicator_data").setData(message.data.data, true);
        const minDate = message.data.data[0][0];
        const maxDate = message.data.data[message.data.data.length - 1][0];
        console.log("minNavigator:", minDate);
        console.log("maxNavigator:", maxDate);
        chart.xAxis[0].setExtremes(minDate, maxDate, true);
      } else if (message.data.type === "target_info") {
        console.log("target_info:", message.data.data);
        chart.setTitle({ text: message.data.data });
      } else if (message.data.type === "start-date-update") {
        startDateInput = message.data.data;
        console.log("startDateInput:", startDateInput);
        console.log("initialLine:", initialLine);
        const newChart = initialLine;
        // .filter(
        //   (point) => point[0] > startDateInput
        // );
        console.log(newChart);
        const prices = newChart.map((point) => {
          return point[1];
        });
        const minPrice = _.min(prices);
        const maxPrice = _.max(prices);
        console.log(minPrice, maxPrice);

        chart.get("target_data").setData(newChart, true);

        chart.yAxis[0].setExtremes(minPrice, maxPrice);
        chart.redraw();
      } else if (message.data.type === "end-date-update") {
        endDateInput = message.data.data;
        console.log("endDateInput:", endDateInput);
        console.log("initialLine:", initialLine);
        const newChart = initialLine;
        // .filter(
        //   (point) => point[0] < endDateInput
        // );
        console.log("newChart:", newChart);

        const prices = newChart.map((point) => {
          return point[1];
        });
        const minPrice = _.min(prices);
        const maxPrice = _.max(prices);
        console.log(minPrice, maxPrice);

        chart.get("target_data").setData(newChart, true);
        var yAxis = chart.yAxis[0];

        yAxis.options.startOnTick = false;
        yAxis.options.endOnTick = false;

        chart.yAxis[0].setExtremes(minPrice, maxPrice);
        chart.redraw();
      } else if (message.data.type === "reset-indicator") {
        chart.get("indicator_data").setData();
      }
    };
    window.addEventListener("message", handleMessage, false);
    return () => {
      window.removeEventListener("message", handleMessage, false);
    };
  }, []);

  useEffect(() => {
    if (navigatorExtremes) {
      console.log("Navigator extremes update:", navigatorExtremes);
      window.postMessage({
        type: "navigator_update",
        data: navigatorExtremes,
      });
    }
  }, [navigatorExtremes]);

  return (
    <div
      id="container-indicator"
      style={{ height: isLoading ? "570px" : "400px", width: "100%" }}
    ></div>
  );
};

export default PredictionLine;
