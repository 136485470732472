import * as React from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import EditIcon from "@mui/icons-material/Edit";

import green from "../assets/red-circle.png";
import red from "../assets/green-circle.png";

function preventDefault(event) {
  event.preventDefault();
}

const IndicatorsTable = ({ tabValue, items, setItemId, handleEdit }) => {
  return (
    <React.Fragment>
      <Title>Recent {tabValue === "one" ? "Indicators" : "Forecasts"}</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="right">Id</TableCell>
            {tabValue === "one" && <TableCell align="right">Status</TableCell>}
            {tabValue === "one" ? (
              <TableCell align="right">Target</TableCell>
            ) : (
              ""
            )}
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow
              key={item.id}
              style={{ cursor: "pointer" }}
              onClick={() => setItemId(item.id)}
            >
              <TableCell>
                {new Date(item.timestamp * 1000).getDate()}/
                {new Date(item.timestamp * 1000).getMonth() + 1}/
                {new Date(item.timestamp * 1000).getFullYear()}
              </TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell align="right">{item.id}</TableCell>
              {tabValue === "one" && (
                <TableCell
                  align="right"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img
                    style={{ height: "20px" }}
                    src={item.status === "success" ? green : red}
                  />{" "}
                  <div>{item.status}</div>
                </TableCell>
              )}
              {tabValue === "one" ? (
                <TableCell align="right">{item.target.column}</TableCell>
              ) : (
                ""
              )}
              {tabValue === "one" ? (
                <TableCell align="right">
                  <EditIcon
                    onClick={(event) => {
                      console.log(event);
                      handleEdit(item.id);
                      event.stopPropagation();
                    }}
                  />
                </TableCell>
              ) : (
                ""
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more {tabValue === "one" ? "indicators" : "forecasts"}
      </Link> */}
    </React.Fragment>
  );
};

export default IndicatorsTable;
