import * as React from 'react';
import Typography from '@mui/material/Typography';
import Title from './Title';

const IndicatorPart1=({indicator})=> {
  return (
    <React.Fragment>
      <Title>Configuration</Title>
      <Typography component="p" 
      // variant="h4"
      >
        <div>
        <ul>
          <li style={{display:"flex"}}>
            <div>target:</div>
            <div>{indicator.target.column}</div>
          </li>
          <li style={{display:"flex"}}>
          <div>start date:</div>
            <div>{indicator.dateInterval.startDate}</div>
          </li>
          <li style={{display:"flex"}}>
          <div>end date:</div>
            <div>{indicator.dateInterval.endDate}</div>
          </li>
        </ul>
    
        <ul>
          <li style={{display:"flex"}}>
            <div>training window:</div>
            <div>{indicator.trainingWindow}</div>
          </li>
          <li style={{display:"flex"}}>
          <div>return period:</div>
            <div>{indicator.returnPeriod}</div>
          </li>
          <li style={{display:"flex"}}>
          <div>rareness:</div>
            <div>{indicator.rareness}</div>
          </li>
          <li style={{display:"flex"}}>
          <div>duration:</div>
            <div>{indicator.duration}</div>
          </li>
        </ul>
        </div>
      </Typography>
      <div>
      </div>
    </React.Fragment>
  );
};

export default IndicatorPart1;