import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const RowRadioButtonsGroup=({setLearningWindow, setExperimentParameters, experimentParameters, startDateInput, endDateInput, experimentResults})=>{
  const handleChange =(event)=>{
    console.log(event.target.value)
      setExperimentParameters({
        ...(experimentParameters ?? {}),
        date_interval: {
          start_date: startDateInput,
          end_date: endDateInput,
          learning_window: event.target.value ? event.target.value : 60
        },
        id: experimentResults?.id,
      });
      setLearningWindow(event.target.value)
    }
  
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        className='radio-buttons'
        defaultValue="60"
      >
        <FormControlLabel className='radio-button' value="60" control={<Radio />} label="2 months ago" onChange={handleChange}/>
        <FormControlLabel className='radio-button' value="120" control={<Radio />} label="4 months ago" onChange={handleChange}/>
        <FormControlLabel className='radio-button' value="180" control={<Radio />} label="6 months ago" onChange={handleChange}/>
      </RadioGroup>
    </FormControl>
  );
}

export default RowRadioButtonsGroup;
