import React, { useEffect } from "react";

import highchartsMore from "highcharts/highcharts-more";
const Highcharts = require("highcharts/highstock");
highchartsMore(Highcharts);

Highcharts.theme = {
  time: {
    useUTC: false,
  },
  colors: [
    "#058DC7",
    "#50B432",
    "#ED561B",
    "#DDDF00",
    "#24CBE5",
    "#64E572",
    "#FF9655",
    "#FFF263",
    "#6AF9C4",
  ],
  chart: {
    backgroundColor: {
      linearGradient: [0, 0, 500, 500],
      stops: [
        [0, "rgb(255, 255, 255)"],
        [1, "rgb(240, 240, 255)"],
      ],
    },
  },
  title: {
    style: {
      color: "#000",
      font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
    },
  },
  subtitle: {
    style: {
      color: "#666666",
      font: 'bold 12px "Trebuchet MS", Verdana, sans-serif',
    },
  },
  legend: {
    itemStyle: {
      font: "9pt Trebuchet MS, Verdana, sans-serif",
      color: "black",
    },
    itemHoverStyle: {
      color: "gray",
    },
  },
};

// Apply the theme
Highcharts.setOptions(Highcharts.theme);

const PredictionLine = ({
  isLoading,
  historicalData,
  predictionData,
  predictedVolatility,
  targetName,
}) => {
  console.log("predictionData:", predictionData);
  console.log("HistoricalData:", historicalData);
  useEffect(() => {
    const createChart = () => {
      const chart = Highcharts.stockChart("container-forecast", {
        navigator: {
          adaptToUpdatedData: false,
        },
        plotOptions: {
          series: {
            showInNavigator: true,
            dataGrouping: {
              enabled: false,
            },
          },
        },
        rangeSelector: {
          enabled: true,
          selected: 5,
        },
        legend: {
          enabled: true,
        },
        subtitle: {
          align: "right",
          x: -10,
        },

        tooltip: {
          useHTML: true,
          headerFormat: "<span>{point.x:%d %b %Y}</span><table>",
          pointFormat:
            "<h3 style='color:{series.color}'>{series.name}</h3><span>Value: </span><b>{point.y}</b> <br/><span>Confidence Level: </span><b>{point.confidenceLevel}%</b> <br/>",
          footerFormat: "</table>",
          followPointer: true,
          valueDecimals: 2,
          xDateFormat: "<b>%d/%m/%Y</b>",
          shared: true,
        },
        // time: {
        //   timezone: params.tz || "UTC"
        // },
        xAxis: {
          type: "datetime",
        },
        yAxis: [
          {
            labels: {
              align: "right",
              x: -3,
            },
            right: "-99%",
            height: "60%",
            offset: 0,
            title: {
              text: "Price",
            },
            lineWidth: 2,
            resize: {
              enabled: true,
            },
          },
          {
            labels: {
              align: "right",
              x: -3,
            },
            top: "65%",
            height: "35%",
            offset: 0,
            title: {
              text: "Volatility",
            },
            lineWidth: 2,
            resize: {
              enabled: true,
            },
          },
        ],
        series: [
          // 0
          {
            name: "Historical Data",
            data: historicalData.hist_data,
            id: "historicalData",
            type: "line",
            tooltip: {
              useHTML: true,
              headerFormat: "<span>{point.x:%d %b %Y}</span><table>",
              pointFormat:
                "<h3 style='color:{series.color}'>{series.name}</h3><span>Value: </span><b>{point.y}</b> <br/>",
              footerFormat: "</table>",
            },
            color: "#9DC7F1",
            yAxis: 0,
          },
          // 1
          {
            name: "Historical Volatility",
            data: historicalData.hist_data_vol,
            id: "historicalVolatility",
            type: "line",
            tooltip: {
              useHTML: true,
              headerFormat: "<span>{point.x:%d %b %Y}</span><table>",
              pointFormat:
                "<h3 style='color:{series.color}'>{series.name}</h3><span>Value: </span><b>{point.y}</b> <br/>",
              footerFormat: "</table>",
            },
            color: "#9DC7F1",
            yAxis: 1,
          },
          // 2
          {
            name: "Prediction Data",
            data: predictionData,
            tooltip: {
              useHTML: true,
              headerFormat: "<span>{point.x:%d %b %Y}</span><table>",
              pointFormat:
                "<h3 style='color:{series.color}'>{series.name}</h3><span>Value: </span><b>{point.y}</b>",
              footerFormat: "</table>",
            },
            id: "predictionData",
            type: "line",
            color: "red",
            opacity: 2,
            yAxis: 0,
          },
          // 3
          {
            name: "Predicted Volatility",
            data: predictedVolatility,
            tooltip: {
              useHTML: true,
              headerFormat: "<span>{point.x:%d %b %Y}</span><table>",
              pointFormat:
                "<h3 style='color:{series.color}'>{series.name}</h3><span>Value: </span><b>{point.y}</b> <br/>",
              footerFormat: "</table>",
            },
            id: "predictedVolatility",
            type: "line",
            color: "orange",
            yAxis: 1,
            opacity: 1,
          },
          // 4
          {
            name: "Confidence Interval",
            data: [],
            tooltip: {
              useHTML: true,
              headerFormat: "<span>{point.x:%d %b %Y}</span><table>",
              pointFormat:
                "<h3 style='color:{series.color}'>{series.name}</h3><b>{point.low}</b> - <b>{point.high}</b> <br/>",
              footerFormat: "</table>",
              // valueSuffix: '%'
            },
            id: "confidence interval",
            type: "arearange",
            color: "red",
            opacity: 0.2,
            yAxis: 0,
          },
        ],
      });
      return chart;
    };
    createChart();
  }, []);

  return (
    <div
      id="container-forecast"
      style={{ height: isLoading ? "570px" : "400px", width: "100%" }}
    ></div>
  );
};

export default PredictionLine;
