import * as React from "react";
import Typography from "@mui/material/Typography";
import Title from "./Title";

const PredictionInfo = ({ prediction }) => {
  return (
    <React.Fragment>
      <Title>Forecast Information</Title>
      <Typography
        component="p"
        // variant="h4"
      >
        <div>
          <ul>
            {prediction.target_name && (
              <li style={{ display: "flex" }}>
                <div>Target: </div>
                <div>{prediction.target_name}</div>
              </li>
            )}
            {prediction.horizon && (
              <li style={{ display: "flex" }}>
                <div>Horizon: </div>
                <div>{prediction.horizon}</div>
              </li>
            )}
            {prediction.highest_predicted_return && (
              <li style={{ display: "flex" }}>
                <div>Highest Predicted Return:</div>
                <div>{prediction.highest_predicted_return}</div>
              </li>
            )}
            {prediction.lowest_predicted_return && (
              <li style={{ display: "flex" }}>
                <div>Lowest Predicted Return:</div>
                <div>{prediction.lowest_predicted_return}</div>
              </li>
            )}
            {prediction?.risk?.expected_var && (
              <li style={{ display: "flex" }}>
                <div>Expected VaR: </div>
                <div>{prediction.risk.expected_var}</div>
              </li>
            )}
            {prediction?.risk?.expected_volatility && (
              <li style={{ display: "flex" }}>
                <div>Expected Volatility: </div>
                <div>{prediction.risk.expected_volatility}</div>
              </li>
            )}
            {prediction.confidence_score && (
              <li style={{ display: "flex" }}>
                <div>Confidence Score: </div>
                <div>{prediction.confidence_score}</div>
              </li>
            )}
          </ul>
        </div>
      </Typography>
      <div></div>
    </React.Fragment>
  );
};

export default PredictionInfo;
