import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

// Import components
import ColoredTab from "../components/ColoredTab";
import CircularLoader from "../components/CircularLoader";
import DisplayChart from "../components/DisplayChart";
import DisplayHeatmap from "../components/DisplayHeatmap";
import IndicatorPart1 from "../components/IndicatorPart1";
import IndicatorPart2 from "../components/IndicatorPart2";
import DisplayForecastChart from "../components/DisplayForecastChart";
import PredictionInfo from "../components/PredictionInfo";
import IndicatorsTable from "../components/IndicatorsTable";
import Header from "../components/Header";
import MenuBar from "../components/MenuBar";

// Import API
import fetchIndicators from "../API/fetchIndicators";
import fetchPredictions from "../API/fetchPredictions";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

const DashboardContent = () => {
  let history = useHistory();
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  // Tabs
  const [tabValue, setTabValue] = useState("one");
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Loader
  const [isLoading, setIsLoading] = useState(true);

  // INDICATORS STATES
  const [indicators, setIndicators] = useState(undefined);
  const [indicatorIdDisplayed, setIndicatorIdDisplayed] = useState(undefined);
  const [indicatorDisplayed, setIndicatorDisplayed] = useState(undefined);
  const [indicatorData, setIndicatorData] = useState(undefined);
  const [targetData, setTargetData] = useState(undefined);
  const [targetName, setTargetName] = useState(undefined);
  const [targetCategory, setTargetCategory] = useState(undefined);
  const [influence, setInfluence] = useState(undefined);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);

  // PREDICTIONS STATES
  const [predictions, setPredictions] = useState(undefined);
  const [predictionIdDisplayed, setPredictionIdDisplayed] = useState(undefined);
  const [predictionDisplayed, setPredictionDisplayed] = useState(undefined);
  const [predictionData, setPredictionData] = useState(undefined);
  const [predictedVolatility, setPredictedVolatility] = useState(undefined);
  const [target, setTarget] = useState(undefined);
  const [tickerId, setTickerId] = useState(undefined);
  const [tickerData, setTickerData] = useState(undefined);
  const [targetStartDate, setTargetStartDate] = useState(undefined);
  const [targetEndDate, setTargetEndDate] = useState(undefined);
  const [predictionImpact, setPredictionImpact] = useState(undefined);

  useEffect(async () => {
    const response = await fetchIndicators();
    setIndicators(
      response.data.sort((a, b) => {
        return b.id - a.id;
      })
    );
    setIndicatorIdDisplayed(
      response.data.sort((a, b) => {
        return b.id - a.id;
      })[0].id
    );
  }, []);

  useEffect(async () => {
    const response = await fetchPredictions();
    setPredictions(
      response.data.sort((a, b) => {
        return b.id - a.id;
      })
    );
    setPredictionIdDisplayed(
      response.data.sort((a, b) => {
        return b.id - a.id;
      })[0].id
    );
  }, []);

  useEffect(async () => {
    if (indicatorIdDisplayed) {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL_MASTER}/indicators/${indicatorIdDisplayed}`,
          {
            headers: {
              token: JSON.parse(localStorage.getItem("user")).token,
            },
          }
        );
        setTargetName(response.data.target.column);
        setTargetCategory(response.data.target.category);
        // console.log("I N D I C A T O R:", indicatorDisplayed);
        setIndicatorDisplayed(response.data);
        setIndicatorData(
          response.data.indicatorData.map((d) => {
            return [d.timestamp, d.value];
          })
        );
        setInfluence(response.data.influence);
        setStartDate(response.data.indicatorData[0].timestamp);
        setEndDate(
          response.data.indicatorData[response.data.indicatorData.length - 1]
            .timestamp
        );
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }
  }, [indicatorIdDisplayed]);

  useEffect(async () => {
    if (predictionIdDisplayed) {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PREDICTOR}/model-predictions/${predictionIdDisplayed}`,
          {
            headers: {
              token: JSON.parse(localStorage.getItem("user")).token,
            },
          }
        );
        console.log("P R E D I C T I O N:", response.data);
        setPredictionDisplayed(response.data);
        setPredictionData(
          Object.values(response.data.prediction)[0].prediction
        );
        setPredictedVolatility(
          Object.values(response.data.prediction)[0].risk?.vol.map((v) => {
            return [v.timestamp, v.vol];
          })
        );
        setTarget(Object.values(response.data.prediction)[0].target_name);
        setTargetStartDate(response.data.dateInterval.startDate_ts);
        setTargetEndDate(response.data.dateInterval.endDate_ts);
        setPredictionImpact(
          Object.values(response.data.prediction)[0].factor_influences
        );
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }
  }, [predictionIdDisplayed]);

  useEffect(async () => {
    if (target) {
      console.log("tickerId:", target);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PREDICTOR}/targets/${target}`,
          {
            date_interval: {
              start_date: targetStartDate,
              end_date: targetEndDate,
            },
          },
          {
            headers: {
              token: JSON.parse(localStorage.getItem("user")).token,
            },
          }
        );
        console.log("GET Target response:", response.data);
        setTickerData(response.data);
      } catch (error) {
        console.log(error);
      }
    }
  }, [target]);

  useEffect(async () => {
    if (
      targetName !== undefined &&
      indicatorDisplayed &&
      indicatorDisplayed.datasourceId
    ) {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_MASTER}/datasources/${indicatorDisplayed.datasourceId}?category=${targetCategory}&column=${targetName}`,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("user")).token,
          },
        }
      );
      setTargetData(
        response.data.map((d) => {
          return [d.timestamp, d.value];
        })
      );
    }
  }, [targetName, indicatorDisplayed]);

  const handleEdit = (indicatorId) => {
    history.push({
      pathname: `/indicators/${indicatorId}`,
      state: {
        indicatorId: indicatorId,
        datasourceId: indicatorDisplayed.datasourceId,
        startDate: indicatorDisplayed.dateInterval.startDate_ts,
        endDate: indicatorDisplayed.dateInterval.endDate_ts,
        min: indicatorDisplayed.dateInterval.min_ts,
        max: indicatorDisplayed.dateInterval.max_ts,
        targetCategory: targetCategory,
        targetName: targetName,

        factorsList: indicatorDisplayed.factorsList,

        factors: indicatorDisplayed.factors,
        explicativeFactors: indicatorDisplayed.explicativeFactors,
        trainingWindow: indicatorDisplayed.trainingWindow,
        returnPeriod: indicatorDisplayed.returnPeriod,
        rareness: indicatorDisplayed.rareness,
        duration: indicatorDisplayed.duration,
        optimization: indicatorDisplayed.optimization,

        targetData: targetData.filter(
          (point) => startDate <= point[0] && point[0] <= endDate
        ),
        indicatorData: indicatorDisplayed.indicatorData.map((d) => {
          return [d.timestamp, d.value];
        }),
        influence: influence,
      },
    });
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Header open={open} toggleDrawer={toggleDrawer} page="Dashboard" />
        </AppBar>
        <MenuBar open={open} Drawer={Drawer} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          {indicators &&
          predictions &&
          indicatorDisplayed &&
          predictionDisplayed ? (
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
              <ColoredTab value={tabValue} handleChange={handleChange} />
              <br />
              <br />
              {tabValue === "one" && indicatorDisplayed ? (
                <>
                  <h2>{indicatorDisplayed.name}</h2>
                  <h3>Target: {indicatorDisplayed.target.column}</h3>
                </>
              ) : (
                tabValue === "two" &&
                predictionDisplayed && (
                  <>
                    {predictionDisplayed.name ? (
                      <h2>{predictionDisplayed.name}</h2>
                    ): ""}
                    <h3>
                      {
                        Object.values(predictionDisplayed.prediction)[0]
                          .target_name
                      }
                    </h3>
                  </>
                )
              )}
              <br />
              <br />
              <Grid container spacing={3}>
                {indicatorDisplayed && targetData && indicatorData && (
                  <Grid item xs={12} md={8} lg={9}>
                    <Paper
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: 400,
                      }}
                    >
                      {tabValue === "one" ? (
                        isLoading ? (
                          <CircularLoader />
                        ) : (
                          <DisplayChart
                            indicatorData={indicatorData}
                            targetData={targetData.filter(
                              (point) =>
                                startDate <= point[0] && point[0] <= endDate
                            )}
                            targetName={targetName}
                          />
                        )
                      ) : isLoading ? (
                        <CircularLoader />
                      ) : (
                        <DisplayForecastChart
                          predictionData={predictionData}
                          predictedVolatility={predictedVolatility}
                          historicalData={tickerData}
                          // .filter(
                          //   (point) =>
                          //     targetStartDate <= point[0] &&
                          //     point[0] <= targetEndDate
                          // )}
                          targetName={targetName}
                        />
                      )}
                    </Paper>
                  </Grid>
                )}
                {tabValue === "one" && indicatorDisplayed && (
                  <Grid item xs={12} md={4} lg={3}>
                    <Paper
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: 400,
                      }}
                    >
                      {isLoading ? (
                        <CircularLoader />
                      ) : (
                        <IndicatorPart1 indicator={indicatorDisplayed} />
                      )}
                    </Paper>
                  </Grid>
                )}

                {influence && predictionImpact && (
                  <Grid item xs={12} md={8} lg={9}>
                    <Paper
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: 400,
                      }}
                    >
                      {isLoading ? (
                        <CircularLoader />
                      ) : (
                        <DisplayHeatmap
                          influence={
                            tabValue === "one" ? influence : predictionImpact
                          }
                        />
                      )}
                    </Paper>
                  </Grid>
                )}
                {tabValue === "one" && indicatorDisplayed && (
                  <Grid item xs={12} md={4} lg={3}>
                    <Paper
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: 300,
                      }}
                    >
                      {isLoading ? (
                        <CircularLoader />
                      ) : (
                        <IndicatorPart2 indicator={indicatorDisplayed} />
                      )}
                    </Paper>
                  </Grid>
                )}

                {tabValue === "two" && predictionDisplayed && (
                  <Grid item xs={12} md={4} lg={3}>
                    <Paper
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: 725,
                        marginTop: "-325px",
                      }}
                    >
                      {isLoading ? (
                        <CircularLoader />
                      ) : (
                        <PredictionInfo
                          prediction={
                            Object.values(predictionDisplayed.prediction)[0]
                          }
                        />
                      )}
                    </Paper>
                  </Grid>
                )}
                {indicators && predictions ? (
                  <Grid item xs={12}>
                    <Paper
                      sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                      <IndicatorsTable
                        tabValue={tabValue}
                        items={tabValue === "one" ? indicators : predictions}
                        setItemId={
                          tabValue === "one"
                            ? setIndicatorIdDisplayed
                            : setPredictionIdDisplayed
                        }
                        handleEdit={handleEdit}
                      />
                    </Paper>
                  </Grid>
                ):
                <CircularLoader/>}
              </Grid>
            </Container>
          ) : (
            <CircularLoader />
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default DashboardContent;
