import React from "react";
import axios from "axios";

const fetchDatasources = async () => {
  return await axios.get(
    `${process.env.REACT_APP_URL_MASTER}/datasources`,
    {
      headers: {
        token: JSON.parse(localStorage.getItem("user")).token,
      },
    }
  );
}

export default fetchDatasources;