import React from "react";
import Button from "@mui/material/Button";

const TemplateFile = ({icon}) => {

  return (
      <Button variant="outlined" startIcon={icon} onClick={()=>{window.open(process.env.PUBLIC_URL + '/template.csv')}}>
          Datasource Template
      </Button>
    )

}

export default TemplateFile;