import React, { useEffect } from "react";
import Highcharts from "highcharts/highmaps";
import _ from "lodash";

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

const getPointCategoryName = (point, dimension) => {
  var series = point.series,
    isY = dimension === "y",
    axis = series[isY ? "yAxis" : "xAxis"];
  return axis.categories[point[isY ? "y" : "x"]];
};

const Heatmap = ({factorsInfluence}) => {
  let title;
  const createChart = () => {
    const chart = Highcharts.chart("container-heatmap", {
      chart: {
        type: "heatmap",
        marginTop: 40,
        marginBottom: 80,
        plotBorderWidth: 1,
      },
      boost: {
        useGPUTranslations: true,
      },
      title: {
        text: "Explicative Factors Influence",
        align: "left",
      },
      xAxis: {
        id: "xAxis",
        minPadding: 0,
        maxPadding: 0,
        startOnTick: false,
        endOnTick: false,
        minTickInterval: 30,
        labels: {
          format: "{value:%Y-%m}",
        },
      },
      yAxis: {
        title: {
          text: null,
        },
        reversed: true,
        id: "yAxis",
        minPadding: 0,
        maxPadding: 0,
        startOnTick: false,
        endOnTick: false,
        tickWidth: 1,
      },
      colorAxis: {
        id: "cAxis",
        min:-1, 
        max:1,
        stops: [
          [0, "#c4463a"],
          [0.1, "#c4463a"],
          [0.5, "#f3f6f4"],
          [1, "#1212FF"],
        ],
        startOnTick: false,
        endOnTick: false,
      },
      legend: {
        align: "right",
        layout: "vertical",
        margin: 0,
        verticalAlign: "top",
        y: 25,
        symbolHeight: 280,
      },
      tooltip: {
        formatter: function () {
          return (
            "<b>" +
            getPointCategoryName(this.point, "y") +
            "</b> has <br><b>" +
            this.point.value +
            "</b> impact on <br><b>" +
            title +
            "</b>" +
            "</b> on <br><b>" +
            formatDate(getPointCategoryName(this.point, "x"))
          );
        },
      },
      series: [
        {
          name: "influence_data",
          id: "influence_data",
          borderWidth: 1,
          data: [],
          boostThreshold: 100,
          borderWidth: 0,
          nullColor: "#EFEFEF",
          colsize: 1,
          turboThreshold: Number.MAX_VALUE,
        },
      ],
    });
    return chart;
  };

  let chart;

  useEffect(() => {
    chart = createChart();
    console.log("chart:", chart)
    const handleMessage = (message) => {
      if (message.data.type === "influence_data") {
        console.log("influence_data:", message.data.data);

        // factors
        const yAxis = Object.keys(message.data.data);
        console.log("YCategories:", yAxis);
        chart.get("yAxis").setCategories(yAxis);

        const xAxis = Object.keys(Object.values(message.data.data)[0]).map(
          (time) => parseInt(time)
        );

        console.log("XCategories:", xAxis);
        chart.get("xAxis").setCategories(xAxis);

        const impactValues = Object.values(message.data.data).flatMap((fac) => {
          return [...Object.values(fac)];
        });

        console.log("impactValues:", impactValues);

        let finalData = [];
        let idx = 0;
        for (let x = 0; x < yAxis.length; x++) {
          for (let i = 0; i < xAxis.length; i++) {
            finalData.push([i, x, impactValues[idx++]]);
          }
        }
        const minMax = _.max([
          Math.abs(_.min(impactValues)),
          Math.abs(_.max(impactValues)),
        ]);
        // chart.get("cAxis").setExtremes(-minMax, minMax);

        console.log("finalData:", finalData);
        chart.get("influence_data").setData(finalData, true);
      } else if (message.data.type === "target_info") {
        console.log("target_info:", message.data.data);
        title = message.data.data;
      } else if (message.data.type === "reset-indicator") {
        chart.destroy();
      }
    };
    window.addEventListener("message", handleMessage, false);
    return () => {
      window.removeEventListener("message", handleMessage, false);
    };
  }, [factorsInfluence]);

  return <div id="container-heatmap"></div>;
};

export default Heatmap;
