import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const DownloadButton = ({handleDownloadCsv}) => {
  return (
    <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <h3></h3>
    <Button
      variant="outlined"
      startIcon={<FileDownloadIcon />}
      onClick={handleDownloadCsv}
    >
      Download
    </Button>
  </div>
  )
}

export default DownloadButton;